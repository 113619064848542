import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  ETAtime,
  getSiteDetails,
  getlocationlist,
  // getlocationlistmapIdv2,
  getmapDetialmapId,
  getproductlist,
  getstorelist,
} from "../API";
import { SliderData } from "../Data/Storelist";
import Footer from "./Footer";
import Header from "./Header";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import { useDispatch, useSelector } from "react-redux";
import { storecurrency, storemapIdaction } from "../redux/Actions";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import CircularProgress from "@mui/material/CircularProgress";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import { Autoplay, Pagination, Navigation } from "swiper/modules";

function StoreList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setloader] = useState(true);
  const [siteDetails, setSiteDetails] = useState();

  const handlestoreclick = (item) => {
    let storeId = item.storeId;
    if (item.type === "hms") {
      dispatch(storecurrency(item.currency));
      dispatch(storemapIdaction(item.mapId));
      navigate("/selectDetails", { state: { storeId, item } });
      localStorage.setItem("store_type", "hms");
    } else {
      dispatch(storecurrency(item.currency));
      dispatch(storemapIdaction(item.mapId));
      navigate(`/storelist/${item.storeId}`, { state: { item: item } });
      localStorage.setItem("store_type", "self");
    }
  };
  const [storelist, setstorelist] = useState([]);
  // const [mapName, setmapName] = useState();
  const { storelanguage } = useSelector((state) => state.storelanguagereducer);
  const { mapId } = useSelector((state) => state.storemapIdreducer);

  useEffect(() => {
    getstorelist()
      .then((res) => {
        // console.log('Storelist response',res)
        setloader(false);
        setstorelist(
          res.data.message
          // ?.sort((a, b) => {
          //   const nameA = a.storeId.toLowerCase();
          //   const nameB = b.storeId.toLowerCase();
          //   if (nameA < nameB) {
          //     return 1;
          //   }
          //   if (nameA > nameB) {
          //     return -1;
          //   }
          //   return 0;
          // })
        );
      })
      .catch((err) => {
        //  console.log(err)
      });
    // getlocationlistmapIdv2(mapId)
    // getlocationlistmapId(storeId)
    //   .then((res) => {
    //     //  console.log('getlocationlistmapId response', res)
    //     setmapName(res.data.data.mapName);
    //   })
    //   .catch((err) => {});
  }, []);

  useEffect(() => {
    getSiteDetails(process.env.REACT_APP_SITE_NAME)
      .then((res) => {
        setSiteDetails(res.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [ETAarray, setETAarray] = useState([]); // Initialize ETAarray with an empty array
  useEffect(() => {
    const promises = storelist?.map((item) => {
      return ETAtime(item.storeId)
        .then((res) => {
          return [res.data.message, res.data.service];
        })
        .catch((err) => {
          // console.error(err);
          return null;
        });
    });
    Promise.all(promises)
      .then((results) => {
        // Filter out null values (failed promises) from the results
        const filteredResults = results.filter((message) => message !== null);
        setETAarray(filteredResults); // Update the state with the resolved messages
      })
      .catch((err) => {
        // console.error(err);
      });
  }, [storelist]);

  return (
    <div className="MainWhole-wrapper-container">
      <Header
        arrow={process.env.REACT_APP_CHANGE_HOME ? true : false}
        siteDetails={siteDetails}
      />
      <div className="ItemListMain-Wrapper">
        <div className="Shop_toGate_wrapper rounded-b-md">
          {siteDetails &&
          siteDetails?.banner?.map(
            (banner) => banner?.languageCode === storelanguage?.code
          )?.length > 1 ? (
            <>
            <Swiper
              centeredSlides={true}
              loop={true}
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Autoplay, Navigation]}
              className="mySwiper"
            >
              {siteDetails?.banner?.map((banner) => (
                <>
                  {banner?.languageCode === storelanguage?.code ? (
                    <SwiperSlide>
                      <img
                        // src="https://imagedelivery.net/HOqw_0sYZTUgFQ-soBOgtg/aa58ab2e-a86f-4c8c-0fb2-90052315d500/public"
                        src={banner?.url}
                        alt=""
                        className="rounded-b-lg"
                        style={{
                          width: "100%",
                          cursor: !!process.env.REACT_APP_MORE_DETAILS
                            ? "pointer"
                            : "auto",
                        }}
                        onClick={
                          !!process.env.REACT_APP_MORE_DETAILS
                            ? () => navigate("/moredetails")
                            : () => {}
                        }
                      />
                    </SwiperSlide>
                  ) : null}
                </>
              ))}
            </Swiper>
            </>
          ) : (
            <>
              {siteDetails &&
                siteDetails?.banner?.map(
                  (banner) => banner?.languageCode === storelanguage?.code
                )?.length === 1 && (
                  <img
                    // src="https://imagedelivery.net/HOqw_0sYZTUgFQ-soBOgtg/aa58ab2e-a86f-4c8c-0fb2-90052315d500/public"
                    src={
                      siteDetails?.banner?.map((banner) => {
                        if (banner?.languageCode === storelanguage?.code) return banner;
                        return null;
                      })[0]?.url
                    }
                    alt=""
                    className="rounded-b-lg"
                    style={{
                      width: "100%",
                      cursor: !!process.env.REACT_APP_MORE_DETAILS
                        ? "pointer"
                        : "auto",
                    }}
                    onClick={
                      !!process.env.REACT_APP_MORE_DETAILS
                        ? () => navigate("/moredetails")
                        : () => {}
                    }
                  />
                )}
            </>
          )}
        </div>

        <div className="Storelist-container-wrapper">
          {
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyItems: "flex-start",
              }}
            >
              <h3 className="!text-[24px]">
                {siteDetails?.siteTexts?.siteText1[storelanguage?.code]}
                <span
                  style={{
                    color: "var(--theme-primary)",
                  }}
                >
                  {" "}
                  {siteDetails?.siteTexts?.siteTextBold1[storelanguage?.code]}
                </span>
              </h3>
              <span className="text-[18px] text-left">
                {siteDetails?.siteTexts?.siteText2[storelanguage?.code]}
                <span
                  style={{
                    color: "var(--theme-primary)",
                    fontWeight: 700,
                  }}
                >
                  {" "}
                  {siteDetails?.siteTexts?.siteTextBold2[storelanguage?.code]}
                </span>
              </span>
            </div>
          }

          {storelist?.length > 0 ? (
            <>
              {storelist?.map((item, index) => {
                if (ETAarray[index] !== undefined) {
                  return (
                    <Fragment key={"store__" + index}>
                      <div
                        className="Storelist_container_inner_item_wrapper"
                        onClick={() => {
                          item.status === 0 ||
                            item.capacity ||
                            !ETAarray[index][1] ||
                            handlestoreclick(item);
                        }}
                      >
                        <div
                          className={
                            item.status === 0 ||
                            item.capacity ||
                            !ETAarray[index][1]
                              ? "rounded-[7px] bg-white inactive_store_with_drop_shadow p-2.5"
                              : "rounded-[7px] bg-white drop-shadow-[0_0_0.5rem_rgba(0,0,0,0.2)] p-2.5"
                          }
                        >
                          <img
                            alt=""
                            src={item.image}
                            style={{
                              borderRadius: "6px",
                            }}
                          />
                        </div>

                        <div
                          className={`Storelist_container_inner_item_wrapper_content_div`}
                        >
                          {item?.status === 0 ||
                            item?.capacity ||
                            !ETAarray[index][1] ? 
                            item?.storeTiming[storelanguage?.code] && (
                            <div className="flex items-center text-left text-[#E50101] font-medium text-[12px] leading-4">
                              <AccessTimeOutlinedIcon className="!w-[1.3rem] !h-[1.3rem] text-[#E50101]" />
                              {item?.storeTiming[storelanguage?.code]}
                            </div>
                              )
                            : item?.estimatedDeliveryTime[
                                storelanguage?.code
                              ] && (
                                <div className="flex items-center text-left text-[var(--theme-primary)] font-medium	 text-[12px] leading-4">
                                  <AccessTimeOutlinedIcon className="!w-[1.3rem] !h-[1.3rem] text-[var(--theme-primary)]" />
                                  {item?.estimatedDeliveryTime[storelanguage?.code]}
                                </div>
                              )}

                          <h4
                            className={`${
                              item?.status === 0 ||
                              item?.capacity ||
                              !ETAarray[index][1]
                                ? "inactive_store !text-[#8f8f8f]"
                                : ""
                            } !text-[16px]`}
                          >
                            {" "}
                            {item?.name[storelanguage?.code]}
                          </h4>

                          <h6
                            className={`
                            ${
                              item?.status === 0 ||
                              item?.capacity ||
                              !ETAarray[index][1]
                                ? "inactive_store"
                                : ""
                            } !text-[14px] !leading-5 text-left`}
                          >
                            {" "}
                            {item?.description[storelanguage?.code]}
                          </h6>

                            <span
                              className={`${
                                item.status === 0 ||
                                item.capacity ||
                                !ETAarray[index][1]
                                  ? "inactive_store"
                                  : ""
                              } text-[var(--theme-primary)] text-left font-[800] text-[12px] leading-[1.15rem]`}
                            >
                              {" "}
                              {item?.topDiscount?.description &&
                                item?.topDiscount?.description[
                                  storelanguage?.code
                                ]}
                            </span>

                          {item.status === 0 ||
                          item.capacity ||
                          !ETAarray[index][1] ? (
                            <>
                              <p className="p_error mt-2 !text-[14px] text-left">
                                {item?.storeClosedMessage[storelanguage?.code]}
                                {/* {
                                  storelanguage?.name === "English"
                                    ? process.env.REACT_APP_STORE_UNAVAILABLE_EN
                                    : process.env.REACT_APP_STORE_UNAVAILABLE_IT
                                  // ? "Currently Unavailable"
                                  // : "Actualmente no disponible"
                                } */}
                              </p>
                            </>
                          ) : (
                            <>
                              <span className="store_location">
                                {item?.location[storelanguage?.code]}
                              </span>
                              <span className="shop__now__btn">
                                {
                                  siteDetails?.siteTexts?.siteOrderText[
                                    storelanguage?.code
                                  ]
                                }
                              </span>
                              {/* {storelanguage?.name === "English" ? (
                                <span className="shop__now__btn">
                                  Order Now
                                </span>
                              ) : (
                                <span className="shop__now__btn">
                                  Ordenar ahora
                                </span>
                              )} */}
                            </>
                          )}
                        </div>
                      </div>
                    </Fragment>
                  );
                }
                return null;
              })}
            </>
          ) : (
            <>
              {" "}
              {loader ? (
                <div className="loader__container">
                  {" "}
                  <CircularProgress className="loader__icon" />
                </div>
              ) : (
                <div className="NoStore_available_text_div">
                  <img src="/assets/images/storeclosed.svg" alt="" />
                  <h4> No Stores Available</h4>{" "}
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default StoreList;
