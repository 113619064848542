import React, { useEffect, useState } from 'react'
import Header from './Header'
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { getSiteDetails } from '../API';
function MoreDetails() {
  const [siteDetails, setSiteDetails] = useState();

  useEffect(() => {
    getSiteDetails(process.env.REACT_APP_SITE_NAME)
      .then((res) => {
        setSiteDetails(res.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const { storelanguage } =useSelector(state=>state.storelanguagereducer)
 

    const {t}= useTranslation()  
    return (
    <>
     <div className='MainWhole-wrapper-container'>
    <Header arrow={false} siteDetails={siteDetails}/>
    <div className='MoreDetails_page_wrapper'>
    <div className="MoreDetials_page_Banner_wrapper"> 
        <img src="/assets/images/Shop_to_gate.svg" alt=""/>
        <img style={{width:"20%"}} src={process.env.REACT_APP_ORDERAT+"/site/"+process.env.REACT_APP_SITE_NAME+"/getAppLogo"}  alt=""/>
     </div>
      <div style={{backgroundColor:"white"}}> 
        <h2 className="More_Details_Page_h2">  {storelanguage?.name==='English'? "More Detail" :"Più dettaglio"}</h2>
        {storelanguage?.name==='English'? 
        <div> 
            <div style={{marginTop:'0px', padding:'20px'}}>   
              <h5 className="More_Details_Page_h5"> Did you know that you can receive your purchases conveniently at the gate?</h5>
              <p className="More-details-page-content-p"> From today in the new pier A of Rome Fiumicino airport it is possible to pay online and receive your purchases directly at the boarding gate, our ottobot will make the delivery!</p>
            </div>
              <div style={{marginTop:'0px', padding:'20px'}}> 
              <h5 className="More_Details_Page_h5">How does the «Shop to gate» service work?</h5>
              <p className="More-details-page-content-p"> Once you have scanned the QR code, select your language and enter one of the two "virtual" shops Aelia or Relay, you can select the products directly from your smartphone.</p>
            </div>
            <div style={{marginTop:'0px', padding:'20px'}}>
              <h5 className="More_Details_Page_h5"> How do I pay and collect the products?</h5>
              <p className="More-details-page-content-p"> Once you have selected the products, you will need to choose the delivery point closest to you. Ottobot has 4 delivery stations in Pier A, locate the one closest to your boarding gate:
                  <p className="More-details-page-content-p"> Gate A61</p>
                  <p className="More-details-page-content-p"> Gate A62-A65 </p>
                  <p className="More-details-page-content-p"> Gate A66-A69 </p>
                  <p className="More-details-page-content-p"> Gate A70-A73 </p>
                  <p className="More-details-page-content-p"> Finalize the purchase with your personal information an/d your credit or debit card information. You will receive a QR code which you will show directly to Ottobot when it arrives at the delivery station. Only you will be able to collect your products!  </p>
                </p>
            </div> 
            <div style={{marginTop:'0px', padding:'20px'}}>
              <h5 className="More_Details_Page_h5"> I was unable to collect the purchased products, what can I do?</h5>
              <p className="More-details-page-content-p"> If for any reason you are unable to collect the products before boarding, you can request a full refund of the expense made via the webapp by accessing the help section. You can also send an email to the address support@ottonomy.io to request assistance.</p>
            </div>
        </div>: 
         <div> 
         <div style={{marginTop:'0px', padding:'20px'}}>   
           <h5 className="More_Details_Page_h5"> Sai che puoi ricevere i tuoi acquisti comodamente al gate?</h5>
           <p className="More-details-page-content-p"> Da oggi nel nuovo molo A dell’aeroporto di Roma Fiumicino è possibile pagare online e ricevere direttamente al gate di imbarco i tuoi acquisti, sarà il nostro ottobot ad effettuare la consegna!</p>
         </div>
           <div style={{marginTop:'0px', padding:'20px'}}> 
           <h5 className="More_Details_Page_h5">Come funziona il servizio «Shop to gate»? </h5>
           <p className="More-details-page-content-p"> Una volta inquadrato il QR code, seleziona la tua lingua ed entra in uno dei due shop «virtuali» Aelia o Relay, puoi selezionare i prodotti direttamente dal tuo smartphone.</p>
         </div>
         <div style={{marginTop:'0px', padding:'20px'}}>
           <h5 className="More_Details_Page_h5"> Come faccio a pagare e ritirare i prodotti?</h5>
           <p className="More-details-page-content-p"> Dopo che hai selezionato i prodotti, dovrai scegliere il punto di consegna più vicino a te. Ottobot ha 4 stazioni di consegna nel molo A, individua quella più vicina al tuo gate di imbarco:
               <p className="More-details-page-content-p"> Gate A61 </p>
               <p className="More-details-page-content-p"> Gate A62-A65</p>
               <p className="More-details-page-content-p"> Gate A66-A69</p>
               <p className="More-details-page-content-p"> Gate A70-A73 </p>
               <p className="More-details-page-content-p">Finalizza l’acquisto con le tue informazioni personali e con i dati della tua carta di credito o debito. Riceverai un QR code che mostrerai direttamente ad Ottobot quando sarà arrivato alla stazione di consegna. Solo tu potrai ritirare i tuoi prodotti!  </p>
             </p>
         </div> 
         <div style={{marginTop:'0px', padding:'20px'}}>
           <h5 className="More_Details_Page_h5"> Non sono riuscito a ritirare i prodotti acquistati, che posso fare?</h5>
           <p className="More-details-page-content-p"> Se per qualunque motivo non riuscissi a ritirare i prodotti prima dell’imbarco, potrai chiedere il rimborso totale della spesa effettuata tramite la webapp, accedendo alla sezione help section. 
Puoi anche inoltrare una mail all’indirizzo support@ottonomy.io per richiedere assistenza.</p>
         </div>
     </div>
        }
     </div> 
    </div>
     
  </div> 
    </>
  )
}

export default MoreDetails