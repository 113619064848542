import {
  Backdrop,
  BottomNavigation,
  BottomNavigationAction,
  Divider,
  Link,
  ToggleButton,
  ToggleButtonGroup,
  styled,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import React, { Fragment } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ProductPageHeader from "./ProductPageHeader";
import Rating from "@mui/material/Rating";
import { DecrementCount, IncrementCount } from "../redux/Actions";
import { productlistdata } from "../Data/Storelist";
import { useDispatch, useSelector } from "react-redux";
import MenuIcon from "@mui/icons-material/Menu";
import Footer from "./Footer";
import { useEffect } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { toast } from "react-toastify";
import { getproductlist } from "../API";
import { IoSearchOutline } from "react-icons/io5";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Header from "./Header";
import VariantToggleButtonGroup from "./VariantToggleButtonGroup";
import { RxCross2 } from "react-icons/rx";
import { IoMdClose } from "react-icons/io";
import { VscClearAll } from "react-icons/vsc";
import ProductDetails from "./ProductDetails";
import Banner from "./Banner";
import { currencyFormatter } from "../utils";
import CircularProgress from "@mui/material/CircularProgress";
import { FiSearch } from "react-icons/fi";

const Search = () => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "calc(560px - 20px)",
    height: "calc(100% - 20px)",
    bgcolor: "#ffffff",
    // boxShadow: 24,
    p: 4,
    borderRadius: "10px",
    paddingTop: "0px",
    paddingBottom: "0px",
    border: "none",
    paddingRight: "0px",
    paddingLeft: "0px",
    zIndex: "999999999999999999999999",
    outline: "none",
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const storedata = useLocation();

  const dispatch = useDispatch();

  const { cart, totalitems, totalPrice } = useSelector((state) => state.IC);
  // if (
  //   !Array.isArray(cart) &&
  //   typeof cart === "object" &&
  //   localStorage.getItem("persist:root")
  // ) {
  //   localStorage.setItem(
  //     "persist:root",
  //     '{"IC":"{\\"cart\\":[],\\"totalitems\\":0,\\"totalPrice\\":0}","ItemsListReducer":"[]","otpNumberReducer":"[]","paymentReducer":"{\\"Payment_refrence\\":{}}","storeCurrencyReducer":"{}","storelanguagereducer":"{\\"storelanguage\\":\\"English\\"}","storemapIdreducer":"{}","_persist":"{\\"version\\":-1,\\"rehydrated\\":true}"}'
  //   );
  //   window.location.reload(false);
  // }
  const [productlist, setproductlist] = useState();
  const [newproductlist, setnewproductlist] = useState();
  const [totalprice, settotalprice] = useState(0);
  const [totalcount, settotalcount] = useState(0);
  const [thirdlastdata, setthirdlstdata] = useState();
  const [categoryWiseProduct, setCategoryWiseProduct] = useState();
  const [categoryWiseSearchResult, setCategoryWiseSearchResult] = useState();
  const [allProductsCategories, setAllProductsCategories] = useState();
  const [allProductsDetails, setAllProductsDetails] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedVariantDetails, setSelectedVariantDetails] = useState();
  const [lastdata, setlastdata] = useState([]);
  const { storeCurrency } = useSelector((state) => state.storeCurrencyReducer);
  const [discountBannerValue, setDiscountBannerValue] = useState(
    storedata?.state?.item?.minCartValue
      ? storedata?.state?.item?.minCartValue
      : process.env.REACT_APP_MIN_CART_VALUE
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  // var lastdata = []
  let countarray = [];
  let secondlastdata = [];

  const { storelanguage } = useSelector((state) => state.storelanguagereducer);

  useEffect(() => {
    getproductlist(storedata?.state?.item?.storeId)
      .then((res) => {
        // console.log('Product list Response', res)
        setproductlist(res.data.message);
      })
      .catch((err) => {
        // console.log("Error in product list response", err)
      });
  }, []);

  useEffect(() => {
    if (
      !storedata.state ||
      storedata.state === undefined ||
      storedata.state === null ||
      storedata.state === "null"
    ) {
      window.location.replace("/");
    }
  }, []);

  useEffect(() => {
    productlist?.map((item) => {
      item.count = 0;
      return null;
    });

    secondlastdata = localStorage.getItem("lastdata");

    if (secondlastdata) {
      try {
        setthirdlstdata(JSON.parse(secondlastdata));
      } catch (error) {
        console.error("Error parsing stored array:", error);
      }
    }

    let groupedData = productlist?.reduce((accumulator, product) => {
      if (
        !accumulator[product.category[storelanguage?.code]] &&
        product.status !== 1
      ) {
        accumulator[product.category[storelanguage?.code]] = [];
      }
      if (product.status !== 1) {
        accumulator[product.category[storelanguage?.code]].push(product);
      }
      return accumulator;
    }, {});

    if (groupedData) {
      // setCategoryWiseProduct(Object.entries(groupedData))

      // const sandwichesIndex = Object.entries(groupedData)?.findIndex(
      //   (item) => item[0] === "Sandwiches"
      // );

      // // If "Sandwiches" category exists, move it to the top
      // if (sandwichesIndex !== -1) {
      //   const sandwichesCategory = Object.entries(groupedData)?.splice(
      //     sandwichesIndex,
      //     1
      //   )[0];
      //   Object.entries(groupedData)?.unshift(sandwichesCategory);
      // }

      // Sort the remaining categories alphabetically
      let tempCatWiseProd = Object.entries(groupedData);

      setCategoryWiseProduct(tempCatWiseProd);

      setAllProductsCategories(
        tempCatWiseProd.map((cat) => {
          return {
            [storelanguage?.code]: cat[1][0]?.category[storelanguage?.code],
            // it: cat[1][0]?.it?.category,
          };
        })
      );
      setAllProductsDetails(tempCatWiseProd);

      // console.log(categoryWiseProduct, "categoryWiseProduct")
      // console.log(allProductsCategories, "allProductsCategories")
      // console.log(allProductsDetails, "allProductsDetails")
    }
  }, [productlist]);

  useEffect(() => {
    document.body.className = "no__scroll";
    // document.html.class = "no__scroll"
  }, []);

  useEffect(() => {
    if (thirdlastdata?.length > 0) {
      setproductlist(
        productlist?.map((itemA) => {
          const matchingObject = thirdlastdata?.find(
            (itemB) => itemB._id === itemA._id
          );
          if (matchingObject) {
            return { ...matchingObject };
          }
          return itemA;
        })
      );
    }
    // console.log('productlist', productlist)
  }, []);

  useEffect(() => {
    let totalpricearr = [];
    lastdata.map((item) => {
      totalpricearr.push(item.price * item.count);
      return null;
    });
    settotalprice(totalpricearr.reduce((a, b) => a + b, 0));
    // console.log('lastdata',lastdata)
    lastdata.map((item) => {
      countarray.push(item.count);
      return null;
    });

    settotalcount(countarray.reduce((a, b) => a + b, 0));
  }, [lastdata, cart]);

  const handleClose = (reason) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
  };

  const navigate = useNavigate();

  const handleNextButton = () => {
    if (discountBannerValue && totalPrice < discountBannerValue) {
      toast.error(
        storedata?.state?.item?.toastTexts?.orderMinimumAmount[
          storelanguage?.code
        ].replace(
          "[variable_value]",
          currencyFormatter(discountBannerValue, storeCurrency)
        )
      );
      // toast.error(
      //   `Minimum order amount is ${currencyFormatter(
      //     discountBannerValue,
      //     storeCurrency
      //   )}. Please add more items.`
      // );
      return;
    }
    sessionStorage.setItem("nextpage", true);
    localStorage.setItem("lastdata", JSON.stringify(lastdata));
    navigate("/billdetails", {
      state: {
        stateone: lastdata,
        statetwo: storedata.state.item,
        statethree: storedata?.state?.productlist,
      },
    });
  };

  const checkAddButton = (item, selectedVariant) => {
    if (selectedVariant && item.variant.length > 0) {
      return (
        cart[selectedVariant.variantId] === 0 ||
        !cart.hasOwnProperty(selectedVariant.variantId)
      );
    } else if (item.variant.length === 0) {
      return cart[item._id] === 0 || !cart.hasOwnProperty(item._id);
    }
  };

  const categoryWiseProductSelection = (category) => {
    if (selectedCategory === category) {
      setSelectedCategory("");
      setCategoryWiseProduct(allProductsDetails);
    } else {
      setSelectedCategory(category);
      if (storelanguage?.name === "English") {
        let tempCatWiseProd = allProductsDetails.filter(
          (catWisProd) => category === catWisProd[1][0].en.category
        );
        setCategoryWiseProduct(tempCatWiseProd);
      } else {
        let tempCatWiseProd = allProductsDetails.filter(
          (catWisProd) => category === catWisProd[1][0].it.category
        );
        setCategoryWiseProduct(tempCatWiseProd);
      }
    }
    // }
    // else {
    //   let selectedCategoryIndex = selectedCategory.findIndex(
    //     (cat) => cat === category
    //   );
    //   let tempCat = selectedCategory;
    //   tempCat.splice(selectedCategoryIndex, 1);
    //   setSelectedCategory([...tempCat]);
    //   let tempCatWiseProd = allProductsDetails.filter((catWisProd) =>
    //     tempCat.includes(catWisProd[0])
    //   );
    //   setCategoryWiseProduct(
    //     tempCatWiseProd.length !== 0 ? tempCatWiseProd : allProductsDetails
    //   );
    // }
  };

  const categoryWiseSearchResultSelection = (category) => {
    if (selectedCategory === category) {
      setSelectedCategory("");
      setCategoryWiseSearchResult(searchResults);
      // setCategoryWiseProduct(allProductsDetails);
    } else {
      setSelectedCategory(category);
      if (storelanguage?.name === "English") {
        let tempCatWiseProd = searchResults.filter(
          (catWisProd) => category === catWisProd[1][0].en.category
        );
        setCategoryWiseSearchResult(tempCatWiseProd);
        // setCategoryWiseProduct(tempCatWiseProd);
      } else {
        let tempCatWiseProd = searchResults.filter(
          (catWisProd) => category === catWisProd[1][0].it.category
        );
        setCategoryWiseSearchResult(tempCatWiseProd);
        // setCategoryWiseProduct(tempCatWiseProd);
      }
    }
    // }
    // else {
    //   let selectedCategoryIndex = selectedCategory.findIndex(
    //     (cat) => cat === category
    //   );
    //   let tempCat = selectedCategory;
    //   tempCat.splice(selectedCategoryIndex, 1);
    //   setSelectedCategory([...tempCat]);
    //   let tempCatWiseProd = allProductsDetails.filter((catWisProd) =>
    //     tempCat.includes(catWisProd[0])
    //   );
    //   setCategoryWiseProduct(
    //     tempCatWiseProd.length !== 0 ? tempCatWiseProd : allProductsDetails
    //   );
    // }
  };

  //   const  levenshteinDistance = (str1, str2) => {
  //     const m = str1.length;
  //     const n = str2.length;
  //     const dp = Array.from({ length: m + 1 }, (_, i) => Array.from({ length: n + 1 }, (_, j) => (i === 0 ? j : (j === 0 ? i : 0))));

  //     for (let i = 1; i <= m; i++) {
  //       for (let j = 1; j <= n; j++) {
  //         const cost = str1[i - 1] === str2[j - 1] ? 0 : 1;
  //         dp[i][j] = Math.min(
  //           dp[i - 1][j] + 1,         // deletion
  //           dp[i][j - 1] + 1,         // insertion
  //           dp[i - 1][j - 1] + cost   // substitution
  //         );
  //       }
  //     }

  //     return dp[m][n];
  //   }

  const handleSearchQuery = (event) => {
    setSelectedCategory("");
    setCategoryWiseProduct(allProductsDetails);
    setSearchTerm(event.target.value);

    if (!event.target.value.replace(/ +/g, "")) {
      setSearchResults([]);
      //   setCategoryWiseProduct([]);
      //   setSelectedCategory("");
      return;
    }

    // const filteredResults = allProductsDetails.filter((category) => {
    //   const [, products] = category;

    //   console.log(products, "PRODUCTS")
    //   const matchingProducts =
    //     storelanguage?.name === "English"
    //       ? products.filter(
    //           (product) =>
    //             product?.en?.name
    //               ?.toLowerCase()?.trim()
    //               ?.includes(event?.target?.value?.toLowerCase()?.trim())
    //             //   ||
    //             // product?.en?.description?.toLowerCase()?.includes(event?.target?.value?.toLowerCase()) ||
    //             // product?.en?.category
    //             //   ?.toLowerCase()?.trim()
    //             //   ?.includes(event?.target?.value?.toLowerCase()?.trim())
    //         )
    //       : products.filter(
    //           (product) =>
    //             product?.it?.name
    //               ?.toLowerCase()?.trim()
    //               ?.includes(event?.target?.value?.toLowerCase()?.trim())
    //             //   ||
    //             // // product?.it?.description?.toLowerCase()?.includes(event?.target?.value?.toLowerCase()) ||
    //             // product?.it?.category
    //             //   ?.toLowerCase()
    //             //   ?.includes(event?.target?.value?.toLowerCase()?.trim())
    //         );

    //         console.log("Matching Products", matchingProducts)
    //   return matchingProducts.length > 0;
    // });

    let filteredProducts = allProductsDetails.map(([category, products]) => {
      let filteredProductItems = products.filter(
        (product) =>
          product?.name[storelanguage?.code]
            ?.toLowerCase()
            ?.replace(/ +/g, "")
            ?.includes(
              event?.target?.value?.toLowerCase()?.replace(/ +/g, "")
            ) ||
          product?.category[storelanguage?.code]
            ?.toLowerCase()
            ?.replace(/ +/g, "")
            ?.includes(event?.target?.value?.toLowerCase()?.replace(/ +/g, ""))
      );

      // console.log(JSON.parse(category), "category")

      return [category, filteredProductItems];
    });

    setSearchResults(filteredProducts);
    setCategoryWiseSearchResult(filteredProducts);
  };

  return (
    <>
      <div className="MainWhole-wrapper-container">
        <div className={`Productlist_page_content_wrapper pt-2`}>
          <div className="flex items-center mx-[20px] bg-[#F0F0F0] rounded-[8px] p-3">
            <input
              type="text"
              onChange={handleSearchQuery}
              value={searchTerm}
              className="w-full text-black placeholder-[#767676] text-[14px] focus:outline-none bg-transparent"
              placeholder={
                storedata?.state?.item?.searchTexts?.placeholder[
                  storelanguage?.code
                ]
              }
            />
            <span
              className="w-[25px] h-[25px] flex justify-center items-center cursor-pointer"
              onClick={() => navigate(-1)}
            >
              <IoMdClose className="w-full h-full stroke-[1.5px] text-red-600" />
            </span>
          </div>
          {/* {!!discountBannerValue && ( <div className="px-8 "></div>)} */}
          <div
            className={`Productlist_page_productlist_wrapper ${
              cart && cart.length > 0
                ? "h-[calc(100dvh_-_170px)]"
                : "h-[calc(100dvh_-_120px)]"
            } mt-2`}
          >
            {categoryWiseProduct?.length > 0 ? (
              <>
                {searchResults.length &&
                categoryWiseSearchResult
                  ?.map((category) => category[1])
                  ?.some((category) => category?.length > 0) ? (
                  <>
                    {categoryWiseSearchResult?.map((category, index) => {
                      return (
                        <Fragment key={"category__no" + index}>
                          {category[1]?.length > 0 && (
                            <div className="mb-4 category__name">
                              <p className="Productlist_page_accordian_heading">
                                {category[1][0]?.category[storelanguage?.code]}
                              </p>
                            </div>
                          )}

                          {category[1]?.map((product, index) => {
                            if (product.status === 0) {
                              return (
                                <Fragment key={"product__no__" + index}>
                                  <ProductDetails
                                    product={product}
                                    setOpen={setOpen}
                                    lastdata={lastdata}
                                    setlastdata={setlastdata}
                                    storedata={storedata}
                                    countarray={countarray}
                                    totalcount={totalcount}
                                  />
                                  {index !== category[1].length - 1 && (
                                    <hr
                                      style={{
                                        margin: "2rem 0",
                                        border: "1px solid rgba(0,0,0,0.2)",
                                      }}
                                    />
                                  )}
                                </Fragment>
                              );
                            }
                            return null;
                          })}
                        </Fragment>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {searchTerm.length > 0 ? (
                      <div className="flex flex-col items-center justify-center w-full h-full mt-3">
                        <span className="w-[200px] h-[200px] flex justify-center items-center opacity-20">
                          <img
                            src="/assets/images/search-icon.svg"
                            alt="search-icon-illustrator"
                          />
                        </span>
                        <span className="flex items-center justify-center w-full font-semibold text-gray-500 text-[14px]">
                          {storelanguage?.name === "English"
                            ? "No Results Found. Sorry about that."
                            : "No se han encontrado resultados. Lo lamento."}
                        </span>
                        <span className="flex w-full items-center justify-center text-gray-500 text-[12px]">
                          {storelanguage?.name === "English"
                            ? "Try searching for something else."
                            : "Intente buscar algo más."}
                        </span>
                      </div>
                    ) : (
                      <div
                        className={`flex items-center justify-center w-full h-full`}
                      >
                        <span className="w-[300px] h-[300px] flex justify-center items-center opacity-20">
                          {/* <FiSearch className="w-full h-full stroke-[1.5px] stroke-[rgba(240,240,240)]" /> */}
                          <img
                            src="/assets/images/search-icon.svg"
                            alt="search-icon-illustrator"
                          />
                        </span>
                      </div>
                    )}
                  </>
                )}
              </>
            ) : (
              <div className="loader__container">
                <CircularProgress className="loader__icon" />
              </div>
            )}
          </div>
        </div>
        <div className="Productlist_page_menu_icon_wrapper">
          <MenuIcon sx={{ fontSize: "40px" }} />
        </div>
        {cart && cart.length > 0 && (
          <div className="Billpage_proceed_tonext_btn_wrapper">
            <h3 className="!text-white text-[22px]">
              {" "}
              {totalitems}{" "}
              {storelanguage?.name === "English"
                ? `${totalitems === 1 ? "item" : "items"}`
                : `${totalitems === 1 ? "artículo" : "elementos"}`}{" "}
              {/* |  */}
              {/* {currencyFormatter(totalPrice.toFixed(2), storeCurrency)} */}
            </h3>
            <button
              onClick={handleNextButton}
              className="BillPage_proceed_to_btn"
            >
              {" "}
              {
                storedata?.state?.item?.productListTexts?.orderText[
                  storelanguage?.code
                ]
              }
              {/* {storelanguage?.name === "English"
                ? "Go to Orders"
                : "Ir al pedido"}{" "} */}
            </button>
          </div>
        )}

        <Modal
          open={open}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="Sorry_div_wrapper">
              <div
                className="Sorry_div_wrapper_inner_div"
                onClick={handleClose}
              >
                <HighlightOffIcon style={{ fontSize: "35px", margin: "5px" }} />
                <p style={{ fontSize: "17px" }}>
                  {" "}
                  {storelanguage?.name === "English" ? "Close" : "Vicina"}{" "}
                </p>
              </div>
              <img src="/assets/images/Emoji_logo.png" alt="" />
              <h4>
                {" "}
                {storelanguage?.name === "English" ? " Sorry !!!" : "Scusa !!!"}
              </h4>
              <p className="Sorry_div_wrapper_p">
                {" "}
                {storelanguage?.name === "English"
                  ? "Cannot add more items. Weight/volume limit reached."
                  : "No se pueden agregar más elementos. Límite de peso/volumen alcanzado."}{" "}
              </p>
              {/* <button className="Sorry_div_wrapper_next_button" onClick={handleNextButton}> View Cart</button> */}
            </div>
          </Box>
        </Modal>

        <Footer />
      </div>
    </>
  );
};

export default Search;
