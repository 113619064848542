import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getlocationlist,
  getstorelist,
  getGeofenceData,
  getSiteDetails,
} from "../API";
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Modal,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { CiCircleList } from "react-icons/ci";
import { CiMap } from "react-icons/ci";
import Header from "./Header";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Footer from "./Footer";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import * as yup from "yup";
import Mapbox from "./Mapbox";
import { currencyFormatter } from "../utils";
import { storecurrency } from "../redux/Actions";

function SelectDelivery() {
  const [deliverylocation, setdeliverylocation] = useState();
  const [deliveryCharge, setDeliveryCharge] = useState(0);
  const [activenextbtn, setactivenextbtn] = useState(false);
  const [mapName, setMapName] = useState();
  const [siteDetails, setSiteDetails] = useState();
  const [showDeliveryType, setShowDeliveryType] = useState(
    process.env.REACT_APP_DEFAULT_DELIVERY_SELECTION ? "map" : "list"
  );
  const [mapMaxBounds, setMapMaxBounds] = useState({});
  const [validation, setvalidation] = useState(false);
  const [allLocationGroups, setAllLocationGroups] = useState([]);
  let alreadySelected = sessionStorage.getItem("deliveryLocation");
  const { storeCurrency } = useSelector((state) => state.storeCurrencyReducer);
  const { storelanguage } = useSelector((state) => state.storelanguagereducer);

  const storetype = localStorage.getItem("store_type");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 350,
    bgcolor: "#ffffff",
    boxShadow: 24,
    p: 4,
    borderRadius: "10px",
    paddingTop: "11px",
    border: "none",
    paddingRight: "0px",
    paddingLeft: "0px",
    zIndex: "999999999999999999999999",
  };
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = (reason) => {
    if (reason && reason == "backdropClick") return;
    setOpen(false);
  };
  const cartData = useLocation();
  const dispatch = useDispatch();

  const { cart, totalitems, totalPrice } = useSelector((state) => state.IC);
  const { mapId } = useSelector((state) => state.storemapIdreducer);
  useEffect(() => {
    // console.log('billdata', billdata)
    setDeliveryCharge(cartData?.state?.statetwo?.deliveryfee);
    let totalpricearr = [];
    cartData?.state?.stateone.map((item) => {
      totalpricearr.push(item.price * item.count);
      return null;
    });
    getGeofenceData(cartData?.state?.statetwo?.storeId)
      .then((res) => {
        setMapName(res?.data?.data?.mapName);
        setMapMaxBounds(res?.data?.data?.maxBounds);
      })
      .catch((err) => {
        console.error("Error in Map Id Locations", err);
      });
  }, []);

  const fetchDeliveryLocations = useCallback(async () => {
    console.log(cartData?.state?.statetwo?.storeId);
    getlocationlist(cartData?.state?.statetwo?.storeId)
      .then((res) => {
        //  console.log('Location List Map Id', mapId)
        // setdeliverylocation(res?.data?.data?.locationList?.sort((a, b) => a.locationName.localeCompare(b.locationName, "en")));
        setdeliverylocation(
          res?.data?.data?.sort((a, b) =>
            a.locationName.localeCompare(b.locationName[storelanguage?.code], "en")
          )
        );

        // console.log(res?.data?.data?.locationList.sort((a,b) => a.locationName - b.locationName))
        // console.log(res?.data?.data?.locationList.sort((a,b) => b.locationName - a.locationName))
      })
      .catch((err) => {
        console.error("Error in Map Id Locations", err);
      });
  }, [mapId]);

  const useIntervalAsync = (callbackFunction, intervalInMs) => {
    const timeout = useRef();

    const apiCall = useCallback(async () => {
      await callbackFunction();
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
      timeout.current = window.setTimeout(apiCall, intervalInMs);
    }, [callbackFunction, intervalInMs]);

    useEffect(() => {
      apiCall();

      return () => {
        clearTimeout(timeout.current);
      };
    }, []);
  };

  // useIntervalAsync(fetchDeliveryLocations, 3000);

  useEffect(() => {
    if (
      !cartData?.state ||
      cartData?.state === undefined ||
      cartData?.state === null ||
      cartData?.state === "null"
    ) {
      window.location.replace("/");
    }
  }, []);

  useEffect(() => {
    getlocationlist(cartData?.state?.statetwo?.storeId)
      .then((res) => {
        //  console.log('Location List Map Id', mapId)
        // setdeliverylocation(res?.data?.data?.locationList?.sort((a, b) => a.locationName.localeCompare(b.locationName, "en")));
        // console.log(res?.data?.data, "DATA");
        setdeliverylocation(
          res?.data?.data?.sort((a, b) =>
            a?.locationName[storelanguage?.code]?.localeCompare(
              b?.locationName[storelanguage?.code],
              "en"
            )
          )
        );

        const uniqueLocationGroups = new Set(
          res?.data?.data
            ?.filter((item) => item.group !== null)
            .map((item) => item.group)
        );

        setAllLocationGroups(Array.from(uniqueLocationGroups));
        // setAllLocationsGroups(res?.data?.data?)
        // console.log(res?.data?.data?.locationList.sort((a,b) => a.locationName - b.locationName))
        // console.log(res?.data?.data?.locationList.sort((a,b) => b.locationName - a.locationName))
      })
      .catch((err) => {
        console.error("Error in Map Id Locations", err);
      });
  }, [showDeliveryType]);

  useEffect(() => {
    getSiteDetails(process.env.REACT_APP_SITE_NAME)
      .then((res) => {
        setSiteDetails(res.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    dispatch(storecurrency(cartData?.state?.statetwo?.currency));
  }, [])

  const navigate = useNavigate();

  // if (
  //   !Array.isArray(cart) &&
  //   typeof cart === "object" &&
  //   localStorage.getItem("persist:root")
  // ) {
  //   localStorage.setItem(
  //     "persist:root",
  //     '{"IC":"{\\"cart\\":[],\\"totalitems\\":0,\\"totalPrice\\":0}","ItemsListReducer":"[]","otpNumberReducer":"[]","paymentReducer":"{\\"Payment_refrence\\":{}}","storeCurrencyReducer":"{}","storelanguagereducer":"{\\"storelanguage\\":\\"English\\"}","storemapIdreducer":"{}","_persist":"{\\"version\\":-1,\\"rehydrated\\":true}"}'
  //   );
  //   window.location.reload(false);
  // }

  const validationSchema = yup.object({
    deliverylocation: yup.object().required("Please Select Delivery location"),
  });

  const formik = useFormik({
    initialValues: {
      deliverylocation: alreadySelected ? alreadySelected : {},
    },
    // validationSchema: validationSchema,
    onSubmit: (values) => {
      if (
        formik.values.deliverylocation &&
        Object.keys(formik.values.deliverylocation).length === 0
      ) {
        setvalidation(true);
        return null;
      } else {
        setvalidation(false);
        sessionStorage.setItem(
          "deliveryLocation",
          values.deliverylocation.locationName
        );
        localStorage.setItem(
          "deliveryLocation",
          values.deliverylocation.locationName
        );
        // console.log('cartDatacartData',cartData)
        getstorelist()
          .then((res) => {
            res.data.message.map((item) => {
              if (
                item.storeId === cartData?.state?.statetwo?.storeId ||
                item.storeId === cartData?.state?.storeId
              ) {
                if (item.status === 0) {
                  handleOpen();
                } else if (item.status === 1) {
                  if (storetype === "hms") {
                    navigate("/details", {
                      state: {
                        locationName: values.deliverylocation,
                        storeId: cartData.state.storeId,
                      },
                    });
                  } else {
                    navigate("/details", {
                      state: {
                        locationName: values.deliverylocation,
                        cartdata: cartData.state.stateone,
                        statetwo: cartData?.state?.statetwo,
                        totalPrice: cartData?.state?.totalPrice,
                        deliveryCharge: cartData?.state?.deliveryCharge,
                        totaltax: cartData?.state?.totaltax,
                        storeId: cartData?.state?.statetwo?.storeId,
                      },
                    });
                  }
                }
              }
              return null;
            });
          })
          .catch((err) => {});
      }
    },
  });

  const handlecartedit = () => {
    // console.log('Cart Details')
    navigate(`/storelist/${cartData?.state?.statetwo?.storeId}`, {
      state: { item: cartData?.state?.statetwo },
    });
  };

  const variantCount = (cartItem) => {
    if (Array.isArray(Object.values(cartItem)[0])) {
      return (
        <>
          {Object.values(cartItem)[0].map((variantItem) => {
            return <h4>{variantItem?.productVariant?.name} x 1</h4>;
          })}
        </>
      );
    } else {
      return <h4>x {Object.values(cartItem)[0]}</h4>;
    }
  };

  return (
    <>
      <div className="MainWhole-wrapper-container">
        <Header siteDetails={siteDetails} />
        <>
          {showDeliveryType === "map" ? (
            // {true ? (
            <div className="DetailsPage-Map-wrapper">
              <div className="h-[calc(100%-170px)] absolute w-full">
                {process.env.REACT_APP_DELIVERY_SELECTION && (
                  <div className="absolute flex items-center w-16 h-7 gap-1 top-[18px] right-3 bg-white z-[1] rounded-[4px]">
                    <span
                      className={`w-[49%] rounded-[4px] ${
                        showDeliveryType === "map"
                          ? "text-white bg-[var(--theme-primary)]"
                          : "text-[var(--theme-primary)]"
                      } cursor-pointer p-1`}
                      onClick={() => setShowDeliveryType("map")}
                    >
                      <CiMap className="w-full h-full" />
                    </span>
                    <span
                      className={`w-[49%] rounded-[4px] cursor-pointer p-1 ${
                        showDeliveryType === "map"
                          ? "text-[var(--theme-primary)]"
                          : "text-white bg-[var(--theme-primary)]"
                      }`}
                      onClick={() => {
                        setShowDeliveryType("list");
                        formik.values.deliverylocation = {};
                      }}
                    >
                      <CiCircleList className="w-full h-full" />
                    </span>
                  </div>
                )}

                {deliverylocation &&
                  deliverylocation?.length > 0 &&
                  Object.keys(mapMaxBounds).length > 0 && (
                    <Mapbox
                      cartData={cartData}
                      deliveryLocations={deliverylocation?.filter(
                        (location) =>
                          location.locationType === "DROPOFF" &&
                          location.status === true
                      )}
                      maxbounds={mapMaxBounds}
                      showDeliveryType={showDeliveryType}
                      storelanguage={storelanguage}
                    />
                  )}
              </div>

              <div className="Billpage_proceed_tonext_btn_wrapper">
                <h3 className="!text-white text-[22px]">
                  {" "}
                  {currencyFormatter(
                    (totalPrice).toFixed(2),
                    storeCurrency
                  )}
                </h3>
                <button className="BillPage_proceed_to_btn !bg-transparent pointer-events-none">
                  ""
                  {/* {storelanguage?.name === "English" ? "Continue" : "Continuar"} */}
                </button>
                {/* <p className="SelectDelivery_page_error_p">
                  {" "}
                  {storelanguage?.name === "English"
                    ? "Please select delivery location"
                    : "Por favor seleccione la ubicación de entrega"}
                </p> */}
              </div>
            </div>
          ) : (
            <div className="DetailsPage-Main-wrapper">
              <h2 className="!relative !text-[18px] !font-bold !text-left">
                
                  
                  <>{cartData?.state?.statetwo?.selectDeliveryTexts?.deliveryTitle[storelanguage?.code]}</>
                
                {process.env.REACT_APP_DELIVERY_SELECTION && (
                  <div className="absolute flex items-center w-16 h-7 gap-1 bottom-4 -right-3 bg-white z-[9] rounded-[4px]">
                    <span
                      className={`w-[49%] rounded-[4px] ${
                        showDeliveryType === "map"
                          ? "text-white bg-[var(--theme-primary)]"
                          : "text-[var(--theme-primary)]"
                      } cursor-pointer p-1`}
                      onClick={() => setShowDeliveryType("map")}
                    >
                      <CiMap className="w-full h-full" />
                    </span>
                    <span
                      className={`w-[49%] rounded-[4px] cursor-pointer p-1 ${
                        showDeliveryType === "map"
                          ? "text-[var(--theme-primary)]"
                          : "text-white bg-[var(--theme-primary)]"
                      }`}
                      onClick={() => setShowDeliveryType("list")}
                    >
                      <CiCircleList className="w-full h-full" />
                    </span>
                  </div>
                )}
              </h2>

              <h3 className="Select_delivery_page_concourse_page_heading !text-[16px]">
                {/* {mapName} */}
              </h3>
              {storelanguage?.name === "English" ? (
                <form onSubmit={formik.handleSubmit}>
                  <div className="mb-[60px] flex flex-col gap-5 h-full w-full">
                    {allLocationGroups?.map((locationGroup, index) => {
                      return (
                        <div
                          key={"location__group__" + index}
                          className="flex w-full h-full flex-col !p-0 bg-white rounded-[10px]"
                        >
                          <div className="w-full text-[var(--theme-primary)] flex items-center p-[1rem] rounded-t-[10px] !text-[16px] font-bold">
                            {locationGroup}
                          </div>
                          <hr />
                          <div className="p-[1rem] !h-full flex flex-wrap gap-3 !justify-start">
                            {deliverylocation &&
                              deliverylocation
                                ?.filter(
                                  (location) =>
                                    location.locationType === "DROPOFF" &&
                                    location.status === true &&
                                    location.group === locationGroup
                                )
                                ?.map((location, index) => (
                                  <BottomNavigation
                                    key={"location__no__" + index}
                                    showLabels
                                    name="deliverylocation"
                                    value={formik.values.deliverylocation}
                                    onChange={(event, newValue) => {
                                      formik.setFieldValue(
                                        "deliverylocation",
                                        newValue
                                      );
                                      setvalidation(true);
                                    }}
                                    className="delivery__location__wrapper"
                                    sx={{
                                      width: "max-content!important",
                                      height: "max-content!important",
                                      padding: "0!important",
                                    }}
                                    error={
                                      formik.touched.deliverylocation &&
                                      Boolean(formik.errors.deliverylocation)
                                    }
                                    // helperText={
                                    //   formik.touched.deliverylocation &&
                                    //   formik.errors.deliverylocation
                                    // }
                                  >
                                    <BottomNavigationAction
                                      sx={{
                                        fontSize: "0.75rem!important",
                                        fontWeight: "normal!important",
                                        border: "1px solid #707070",
                                        borderRadius: "10px!important",
                                        color: "black",
                                      }}
                                      key={index}
                                      label={location?.locationName[
                                        [storelanguage?.code]
                                      ]
                                        ?.split("_")
                                        ?.join(" ")
                                        ?.toLowerCase()}
                                      className="!capitalize"
                                      // className="!text-sm !font-normal !px-3 !py-2 !rounded-[10px] !border-2 !border-[#707070]"
                                      value={location}
                                    />
                                  </BottomNavigation>
                                ))}
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  <div
                    className={`Billpage_proceed_tonext_btn_wrapper ${
                      validation
                        ? "!bg-[var(--theme-primary)]"
                        : "!bg-[#707070]"
                    }`}
                  >
                    <h3 className="!text-white text-[22px]">
                      {" "}
                      {currencyFormatter(
                        (totalPrice).toFixed(2),
                        storeCurrency
                      )}
                    </h3>

                    <button
                      className={`BillPage_proceed_to_btn !text-[16px] ${
                        validation
                          ? "!text-[var(--theme-primary)]"
                          : "!text-[#707070]"
                      }`}
                      disabled={validation ? false : true}
                    >
                      {" "}
                      {cartData?.state?.statetwo?.selectDeliveryTexts?.continue[storelanguage?.code]}
                      {/* {storelanguage?.name === "English"
                        ? "Continue"
                        : "Continuar"} */}
                    </button>
                  </div>
                </form>
              ) : (
                <form onSubmit={formik.handleSubmit}>
                  <div className="mb-[60px] flex flex-col gap-5 h-full w-full">
                    {allLocationGroups?.map((locationGroup, index) => {
                      return (
                        <div
                          key={"location__group__" + index}
                          className="flex w-full h-full flex-col !p-0 bg-white rounded-[10px]"
                        >
                          <div className="w-full text-[var(--theme-primary)] flex items-center p-[1rem] rounded-t-[10px] !text-[16px] font-bold">
                            {locationGroup}
                          </div>
                          <hr />
                          <div className="p-[1rem] !h-full flex flex-wrap gap-3 !justify-start">
                            {deliverylocation &&
                              deliverylocation
                                ?.filter(
                                  (location) =>
                                    location.locationType === "DROPOFF" &&
                                    location.status === true &&
                                    location.group === locationGroup
                                )
                                ?.map((location, index) => (
                                  <BottomNavigation
                                    key={"location__no__" + index}
                                    showLabels
                                    name="deliverylocation"
                                    value={formik.values.deliverylocation}
                                    onChange={(event, newValue) => {
                                      formik.setFieldValue(
                                        "deliverylocation",
                                        newValue
                                      );
                                      setvalidation(true);
                                    }}
                                    className="delivery__location__wrapper"
                                    sx={{
                                      width: "max-content!important",
                                      height: "max-content!important",
                                      padding: "0!important",
                                    }}
                                    error={
                                      formik.touched.deliverylocation &&
                                      Boolean(formik.errors.deliverylocation)
                                    }
                                  >
                                    <BottomNavigationAction
                                      sx={{
                                        fontSize: "0.75rem!important",
                                        fontWeight: "normal!important",
                                        border: "1px solid #707070",
                                        borderRadius: "10px!important",
                                        color: "black",
                                      }}
                                      key={index}
                                      label={location?.locationName[
                                        [storelanguage?.code]
                                      ]
                                        ?.split("_")
                                        .join(" ")
                                        .toLowerCase()}
                                      className="!capitalize"
                                      value={location}
                                    />
                                  </BottomNavigation>
                                ))}
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  <div
                    className={`Billpage_proceed_tonext_btn_wrapper ${
                      validation
                        ? "!bg-[var(--theme-primary)]"
                        : "!bg-[#707070]"
                    }`}
                  >
                    <h3 className="!text-white text-[22px]">
                      {" "}
                      {currencyFormatter(
                        (totalPrice).toFixed(2),
                        storeCurrency
                      )}
                    </h3>
                    <button
                      className={`BillPage_proceed_to_btn !text-[16px] ${
                        validation
                          ? "!text-[var(--theme-primary)]"
                          : "!text-[#707070]"
                      }`}
                      disabled={validation ? false : true}
                    >
                      {" "}
                      {cartData?.state?.statetwo?.selectDeliveryTexts?.continue[storelanguage?.code]}
                    </button>
                  </div>
                </form>
              )}

              <div className="flex items-center justify-center w-full">
                <img
                  className="rounded-md"
                  src={`${process.env.REACT_APP_ORDERAT}/store/${cartData?.state?.statetwo?.storeId}/getReferenceMapImage`}
                  // src={`https://imagedelivery.net/HOqw_0sYZTUgFQ-soBOgtg/c1bc42b1-46a2-468f-4c83-033882c64100/public`}
                  onError={(event) => (event.target.style.display = "none")}
                  alt=""
                />
              </div>
            </div>
          )}
        </>
        <Modal
          open={open}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="Sorry_div_wrapper">
              <div
                className="Sorry_div_wrapper_inner_div"
                onClick={handleClose}
              >
                <HighlightOffIcon style={{ fontSize: "35px", margin: "5px" }} />
                <p style={{ fontSize: "17px" }}>
                  {storelanguage?.name === "English" ? "Close" : "Vicina"}
                </p>
              </div>
              <img src="/assets/images/Emoji_logo.png" alt="" />
              <h4>
                {" "}
                {storelanguage?.name === "English" ? "Sorry !!!" : "Scusa !!!"}
              </h4>
              <p className="Sorry_div_wrapper_p">
                {" "}
                {storelanguage?.name === "English"
                  ? "Store is closed, please try again later"
                  : "La tienda está cerrada, inténtalo de nuevo más tarde"}{" "}
              </p>
              {/* <button className="Sorry_div_wrapper_next_button" onClick={handleNextButton}> View Cart</button> */}
            </div>
          </Box>
        </Modal>
        <Footer />
      </div>
    </>
  );
}

export default SelectDelivery;
