import axios from "axios";
import { error } from "jquery";

export const login = (values) => {
  return axios
    .post(`${process.env.REACT_APP_ORDERAT}login/v1/login`, {
      emailId: values?.email,
      password: values?.password,
    })
    .then((res) => {
      return res;
    });
};

export const sendMessage = (phoneNo, otp, order = {}) => {
  let { language, customerName } = order;
  let message = "";
  if (language === "English") {
    message = `Hi ${customerName}, your verification code for the OrderAt is: ${otp}.`;
  } else if (language === "Spanish") {
    message = `¡Hola ${customerName}, Gracias por realizar tu pedido. Confirma tu teléfono móvil en la aplicación introduciendo este código: ${otp}.`;
  } else if (language === "Italian") {
    message = `Buongiorno ${customerName}, il tuo codice di verifica per OrderAt è: ${otp}.`;
  }
  return axios
    .post(
      `${process.env.REACT_APP_ORDERAT}/user/v1/sendMessage`,
      {
        //    number:`9977950807`,
        number: `+${phoneNo}`,
        message,
        //    message:`Your One-Time Password (OTP) verification code is:${message}`
      },
      {
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2MDNiNzBmNzc3MjA2MTQ1MWNmNzdhMzIiLCJkYXRlT2ZSZWdpc3RlcmF0aW9uIjoiMjAyMS0wMi0yOFQxMDozMToxOS4xMTNaIiwidXNlck5hbWUiOiJ0ZXN0LnVzZXIiLCJlbWFpbElkIjoidGVzdHVzZXJAdGVzdC5jb20iLCJmaXJzdE5hbWUiOiJ0ZXN0IiwibGFzdE5hbWUiOiJ1c2VyIiwibWlkZGxlTmFtZSI6IiIsInBhc3N3b3JkIjoidGVzdEAxMjMiLCJyb2xlIjoidGVzdCIsImltYWdlVXJsIjoidGVzdCIsImNyZWF0ZWRfYXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJfX3YiOjB9.N1fVw8a2_YA5LeomrbrM1GPDRc__qtMkOeHFWiCABmg`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      //    console.log('Send Message response', response)
      return response;
    })
    .catch((error) => {
      console.error("Send Message Error", error);
      return error;
    });
};

export const sendOTP = (phoneNo, customerName, language) => {
  return axios
    .post(`${process.env.REACT_APP_ORDERAT}/user/sendOtp`, {
      phoneNumber: phoneNo,
      customerName: customerName,
      language: language,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error("Send Message Error", error);
      return error;
    });
};

export const verifyOtp = (phoneNo, otp) => {
  return axios
    .post(`${process.env.REACT_APP_ORDERAT}/user/verifyOtp`, {
      phoneNumber: phoneNo,
      otp: otp,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error("Verify Message Error", error);
      return error;
    });
};

export const sendMessagetwo = (phoneNo, message) => {
  return axios
    .post(
      `${process.env.REACT_APP_ORDERAT}/user/v1/sendMessage`,
      {
        number: `+${phoneNo}`,
        message: message,
      },
      {
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2MDNiNzBmNzc3MjA2MTQ1MWNmNzdhMzIiLCJkYXRlT2ZSZWdpc3RlcmF0aW9uIjoiMjAyMS0wMi0yOFQxMDozMToxOS4xMTNaIiwidXNlck5hbWUiOiJ0ZXN0LnVzZXIiLCJlbWFpbElkIjoidGVzdHVzZXJAdGVzdC5jb20iLCJmaXJzdE5hbWUiOiJ0ZXN0IiwibGFzdE5hbWUiOiJ1c2VyIiwibWlkZGxlTmFtZSI6IiIsInBhc3N3b3JkIjoidGVzdEAxMjMiLCJyb2xlIjoidGVzdCIsImltYWdlVXJsIjoidGVzdCIsImNyZWF0ZWRfYXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJfX3YiOjB9.N1fVw8a2_YA5LeomrbrM1GPDRc__qtMkOeHFWiCABmg`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      // console.log("Send Message response", response);
      return response;
    })
    .catch((error) => {
      console.error("Send Message Error", error);
      return error;
    });
};

export const sendmsglocation = (phoneNo, message) => {
  return axios
    .post(
      `${process.env.REACT_APP_ORDERAT}/user/v1/sendMessage`,
      {
        number: `+${phoneNo}`,
        message: `Please click the link and scan the QR Code - ${message}`,
      },
      {
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2MDNiNzBmNzc3MjA2MTQ1MWNmNzdhMzIiLCJkYXRlT2ZSZWdpc3RlcmF0aW9uIjoiMjAyMS0wMi0yOFQxMDozMToxOS4xMTNaIiwidXNlck5hbWUiOiJ0ZXN0LnVzZXIiLCJlbWFpbElkIjoidGVzdHVzZXJAdGVzdC5jb20iLCJmaXJzdE5hbWUiOiJ0ZXN0IiwibGFzdE5hbWUiOiJ1c2VyIiwibWlkZGxlTmFtZSI6IiIsInBhc3N3b3JkIjoidGVzdEAxMjMiLCJyb2xlIjoidGVzdCIsImltYWdlVXJsIjoidGVzdCIsImNyZWF0ZWRfYXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJfX3YiOjB9.N1fVw8a2_YA5LeomrbrM1GPDRc__qtMkOeHFWiCABmg`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      //  console.log('Send Message Location response', response)
      return response;
    })
    .catch((error) => {
      //  console.log('Send Message Location Error', error)
      return error;
    });
};

export const placeorder = (
  deliveryLocation,
  contactno,
  orderId,
  itemName,
  itemQuantity
) => {
  // let newarray =itemName.concat(itemQuantity)
  let stringnew = "";
  let finalData = [];
  for (var i = 0; i < itemName.length; i++) {
    stringnew = stringnew + itemQuantity[i] + "x" + itemName[i] + " ";
  }
  // let finalstring = JSON.stringify(finalData)
  // console.log('stringnew',stringnew)
  return axios
    .post(
      `https://api.orderat.io/order/v1/placeOrder`,
      {
        orderId: orderId,
        fleetId: "61f7886316bad77abc2f6bb2",
        customerName: "",
        customerMobileNumber: contactno,
        customerEmail: stringnew,
        orderDetails: "",
        orderValue: "999",
        pickupId: "KITCHEN",
        dropId: deliveryLocation,
        qrcodePickup: orderId,
        qrcodeDrop: orderId,
        timeout: "180",
        timeoutBeh: "1000",
        deadline: "1000",
        pin: "",
      },
      {
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2MDNiNzBmNzc3MjA2MTQ1MWNmNzdhMzIiLCJkYXRlT2ZSZWdpc3RlcmF0aW9uIjoiMjAyMS0wMi0yOFQxMDozMToxOS4xMTNaIiwidXNlck5hbWUiOiJ0ZXN0LnVzZXIiLCJlbWFpbElkIjoidGVzdHVzZXJAdGVzdC5jb20iLCJmaXJzdE5hbWUiOiJ0ZXN0IiwibGFzdE5hbWUiOiJ1c2VyIiwibWlkZGxlTmFtZSI6IiIsInBhc3N3b3JkIjoidGVzdEAxMjMiLCJyb2xlIjoidGVzdCIsImltYWdlVXJsIjoidGVzdCIsImNyZWF0ZWRfYXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJfX3YiOjB9.N1fVw8a2_YA5LeomrbrM1GPDRc__qtMkOeHFWiCABmg`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      // console.log(" Placeorder response", response);
      return response;
    })
    .catch((error) => {
      console.error("placeorder Error", error);
      return error;
    });
};

export const getlocationlist = (storeId) => {
  return axios
    .post(`${process.env.REACT_APP_ORDERAT}/store/getLocationList`, {
      storeId: storeId,
    })
    .then((res) => {
      return res;
    });
};

export const getGeofenceData = (storeId) => {
  return axios
    .post(`${process.env.REACT_APP_ORDERAT}/store/getGeofenceData`, {
      storeId: storeId,
    })
    .then((res) => {
      return res;
    });
};

export const getmapDetialmapId = (mapId) => {
  return axios
    .post(
      `${process.env.REACT_APP_ORDERAT}/map/v1/getMapDetailById`,
      {
        mapId: mapId,
      },
      {
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2MDNiNzBmNzc3MjA2MTQ1MWNmNzdhMzIiLCJkYXRlT2ZSZWdpc3RlcmF0aW9uIjoiMjAyMS0wMi0yOFQxMDozMToxOS4xMTNaIiwidXNlck5hbWUiOiJ0ZXN0LnVzZXIiLCJlbWFpbElkIjoidGVzdHVzZXJAdGVzdC5jb20iLCJmaXJzdE5hbWUiOiJ0ZXN0IiwibGFzdE5hbWUiOiJ1c2VyIiwibWlkZGxlTmFtZSI6IiIsInBhc3N3b3JkIjoidGVzdEAxMjMiLCJyb2xlIjoidGVzdCIsImltYWdlVXJsIjoidGVzdCIsImNyZWF0ZWRfYXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJfX3YiOjB9.N1fVw8a2_YA5LeomrbrM1GPDRc__qtMkOeHFWiCABmg`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
// export const getdeliverytime = (orderId) => {
//   return axios
//     .post(
//       `https://api.orderat.io/order/details`,
//       {
//         orderId: orderId,
//       },
//       {
//         headers: {
//           Authorization: `Bearer eyJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2MDNiNzBmNzc3MjA2MTQ1MWNmNzdhMzIiLCJkYXRlT2ZSZWdpc3RlcmF0aW9uIjoiMjAyMS0wMi0yOFQxMDozMToxOS4xMTNaIiwidXNlck5hbWUiOiJ0ZXN0LnVzZXIiLCJlbWFpbElkIjoidGVzdHVzZXJAdGVzdC5jb20iLCJmaXJzdE5hbWUiOiJ0ZXN0IiwibGFzdE5hbWUiOiJ1c2VyIiwibWlkZGxlTmFtZSI6IiIsInBhc3N3b3JkIjoidGVzdEAxMjMiLCJyb2xlIjoidGVzdCIsImltYWdlVXJsIjoidGVzdCIsImNyZWF0ZWRfYXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJfX3YiOjB9.N1fVw8a2_YA5LeomrbrM1GPDRc__qtMkOeHFWiCABmg`,
//           "Content-Type": "application/json",
//         },
//       }
//     )
//     .then((res) => {
//       return res;
//     });
// };

export const getdeliverytime = (orderId) => {
  return axios
    .get(`${process.env.REACT_APP_APIOTTONOMY_URL}getOrdersETA/${orderId}`, {
      headers: {
        Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTU0NTk1Y2E3MTZiMWI5YmJjNWY4NDMiLCJpYXQiOjE3MDQ4ODkyMzJ9.GBJ6MxcRwvJCn3ISPzcWTiVcfuxyVZxm9hejPcAa_Lk`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      return res;
    });
};

export const getOrderDetails = (orderID) => {
  return axios
    .post(
      `${process.env.REACT_APP_ORDERAT}/order/details`,
      {
        orderId: orderID,
      }
      // {
      //   headers: {
      //     Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTU0NTk1Y2E3MTZiMWI5YmJjNWY4NDMiLCJpYXQiOjE3MDQ4ODkyMzJ9.GBJ6MxcRwvJCn3ISPzcWTiVcfuxyVZxm9hejPcAa_Lk`,
      //     "Content-Type": "application/json",
      //   },
      // }
    )
    .then((res) => {
      return res;
    });
};

export const updateOrderStatus = (orderId) => {
  return axios
    .post(
      `https://api.orderat.io/order/updateOrderStatus`,
      {
        orderId: orderId,
        status: "",
        statusCode: "",
      },
      {
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2MDNiNzBmNzc3MjA2MTQ1MWNmNzdhMzIiLCJkYXRlT2ZSZWdpc3RlcmF0aW9uIjoiMjAyMS0wMi0yOFQxMDozMToxOS4xMTNaIiwidXNlck5hbWUiOiJ0ZXN0LnVzZXIiLCJlbWFpbElkIjoidGVzdHVzZXJAdGVzdC5jb20iLCJmaXJzdE5hbWUiOiJ0ZXN0IiwibGFzdE5hbWUiOiJ1c2VyIiwibWlkZGxlTmFtZSI6IiIsInBhc3N3b3JkIjoidGVzdEAxMjMiLCJyb2xlIjoidGVzdCIsImltYWdlVXJsIjoidGVzdCIsImNyZWF0ZWRfYXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJfX3YiOjB9.N1fVw8a2_YA5LeomrbrM1GPDRc__qtMkOeHFWiCABmg`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const OrderQrcode = (orderId, url) => {
  return axios
    .post(
      `https://api.orderat.io/order/updateQrCode `,
      {
        orderId: orderId,
        fleetId: "61f7886316bad77abc2f6bb2",
        qrLink: url,
      },
      {
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2MDNiNzBmNzc3MjA2MTQ1MWNmNzdhMzIiLCJkYXRlT2ZSZWdpc3RlcmF0aW9uIjoiMjAyMS0wMi0yOFQxMDozMToxOS4xMTNaIiwidXNlck5hbWUiOiJ0ZXN0LnVzZXIiLCJlbWFpbElkIjoidGVzdHVzZXJAdGVzdC5jb20iLCJmaXJzdE5hbWUiOiJ0ZXN0IiwibGFzdE5hbWUiOiJ1c2VyIiwibWlkZGxlTmFtZSI6IiIsInBhc3N3b3JkIjoidGVzdEAxMjMiLCJyb2xlIjoidGVzdCIsImltYWdlVXJsIjoidGVzdCIsImNyZWF0ZWRfYXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJfX3YiOjB9.N1fVw8a2_YA5LeomrbrM1GPDRc__qtMkOeHFWiCABmg`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const getstorelist = () => {
  return axios
    .post(
      `${process.env.REACT_APP_ORDERAT}/store/customer/list`,
      {
        zone: process.env.REACT_APP_ZONE,
      },
      {
        headers: {
          // 'Authorization':`Bearer eyJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2MDNiNzBmNzc3MjA2MTQ1MWNmNzdhMzIiLCJkYXRlT2ZSZWdpc3RlcmF0aW9uIjoiMjAyMS0wMi0yOFQxMDozMToxOS4xMTNaIiwidXNlck5hbWUiOiJ0ZXN0LnVzZXIiLCJlbWFpbElkIjoidGVzdHVzZXJAdGVzdC5jb20iLCJmaXJzdE5hbWUiOiJ0ZXN0IiwibGFzdE5hbWUiOiJ1c2VyIiwibWlkZGxlTmFtZSI6IiIsInBhc3N3b3JkIjoidGVzdEAxMjMiLCJyb2xlIjoidGVzdCIsImltYWdlVXJsIjoidGVzdCIsImNyZWF0ZWRfYXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJfX3YiOjB9.N1fVw8a2_YA5LeomrbrM1GPDRc__qtMkOeHFWiCABmg`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const getSiteDetails = (siteName) => {
  return axios.post(
    `${process.env.REACT_APP_ORDERAT}/site/details`,
    {
      siteName: siteName,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const getproductlist = (storeId) => {
  return axios
    .post(
      `${process.env.REACT_APP_ORDERAT}/product/list`,
      {
        storeId: storeId,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

// export const orderbooking=(orderId,data)=>{
//     return axios.post(`https://sandbox.ottonomy.io/order/booking`,{
//         orderId: orderId,
//         customerName:data.username,
//         customerMobileNumber:data.contactNo,
//         customerEmail:"",
//         orderValue:"",
//         orderDetails:"",
//         pickupId: "INP_MADRASCANTEEN",
//         dropId: data.locationName,
//     },{
//         headers:{
//             'Authorization':`Bearer eyJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2MDNiNzBmNzc3MjA2MTQ1MWNmNzdhMzIiLCJkYXRlT2ZSZWdpc3RlcmF0aW9uIjoiMjAyMS0wMi0yOFQxMDozMToxOS4xMTNaIiwidXNlck5hbWUiOiJ0ZXN0LnVzZXIiLCJlbWFpbElkIjoidGVzdHVzZXJAdGVzdC5jb20iLCJmaXJzdE5hbWUiOiJ0ZXN0IiwibGFzdE5hbWUiOiJ1c2VyIiwibWlkZGxlTmFtZSI6IiIsInBhc3N3b3JkIjoidGVzdEAxMjMiLCJyb2xlIjoidGVzdCIsImltYWdlVXJsIjoidGVzdCIsImNyZWF0ZWRfYXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJfX3YiOjB9.N1fVw8a2_YA5LeomrbrM1GPDRc__qtMkOeHFWiCABmg`,
//             'Content-Type': 'application/json',
//         }
//     })
//     .then((res)=>{
//        return res
//     })
// }
export const orderbooking = (
  orderId,
  data,
  productsData,
  totalPrice,
  deliveryCharge,
  pickup,
  drop,
  totaltax,
  storeCurrency,
  cart,
  storelanguage,
  availableDiscount
) => {
  let newdeliverycharge = deliveryCharge.toFixed(3);
  let carttotal = parseFloat(totalPrice);

  //  console.log('orderId',orderId,orderId.substr(-4))
  return axios
    .post(
      `${process.env.REACT_APP_ORDERAT}/order/booking`,
      {
        storeId: data?.statetwo?.storeId,
        orderId: orderId,
        customer: data?.username,
        phone: data.contactNo,
        language: storelanguage,
        products: productsData,
        total: availableDiscount?.discountedAmount
          ? availableDiscount?.cartTotalAfterDiscount +
            parseFloat(availableDiscount?.deliveryFeeAfterDiscount) +
            parseFloat(availableDiscount?.taxAfterDiscount)
          : totalPrice + parseFloat(newdeliverycharge) + parseFloat(totaltax),
        discount: availableDiscount?.discountedAmount,
        discountCode: availableDiscount?.discountCode,
        tax: totaltax,
        cartTotal: carttotal,
        currency: storeCurrency,
        status: "booked",
        drop: drop,
        pick: pickup,
        deliveryfee: newdeliverycharge
        // pin: orderId.substr(-4),
      },
      {
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2MDNiNzBmNzc3MjA2MTQ1MWNmNzdhMzIiLCJkYXRlT2ZSZWdpc3RlcmF0aW9uIjoiMjAyMS0wMi0yOFQxMDozMToxOS4xMTNaIiwidXNlck5hbWUiOiJ0ZXN0LnVzZXIiLCJlbWFpbElkIjoidGVzdHVzZXJAdGVzdC5jb20iLCJmaXJzdE5hbWUiOiJ0ZXN0IiwibGFzdE5hbWUiOiJ1c2VyIiwibWlkZGxlTmFtZSI6IiIsInBhc3N3b3JkIjoidGVzdEAxMjMiLCJyb2xlIjoidGVzdCIsImltYWdlVXJsIjoidGVzdCIsImNyZWF0ZWRfYXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJfX3YiOjB9.N1fVw8a2_YA5LeomrbrM1GPDRc__qtMkOeHFWiCABmg`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const checkDiscountAvailability = (discountData) => {
  return axios
    .post(
      `${process.env.REACT_APP_ORDERAT}/discount/checkAvailability`,
      {
        code: discountData.code,
        storeId: discountData.storeId,
        products: discountData.products,
        total: discountData.total,
        cartTotal: discountData.cartTotal,
        orderId: discountData.orderId,
        tax: discountData.tax,
        customer: discountData.customer,
        phone: discountData.phone,
        deliveryFee: discountData.deliveryFee
      },
      {
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2MDNiNzBmNzc3MjA2MTQ1MWNmNzdhMzIiLCJkYXRlT2ZSZWdpc3RlcmF0aW9uIjoiMjAyMS0wMi0yOFQxMDozMToxOS4xMTNaIiwidXNlck5hbWUiOiJ0ZXN0LnVzZXIiLCJlbWFpbElkIjoidGVzdHVzZXJAdGVzdC5jb20iLCJmaXJzdE5hbWUiOiJ0ZXN0IiwibGFzdE5hbWUiOiJ1c2VyIiwibWlkZGxlTmFtZSI6IiIsInBhc3N3b3JkIjoidGVzdEAxMjMiLCJyb2xlIjoidGVzdCIsImltYWdlVXJsIjoidGVzdCIsImNyZWF0ZWRfYXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJfX3YiOjB9.N1fVw8a2_YA5LeomrbrM1GPDRc__qtMkOeHFWiCABmg`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const orderconfirm = (
  cartData,
  paymentVia,
  paymentReference,
  cart,
  totalPrice
) => {
  let carttotal = parseFloat(totalPrice);
  let newdeliverycharge =
    cartData?.state?.cartdata?.state?.deliveryCharge.toFixed(3);
  //    console.log('cartData',cartData)
  return axios
    .post(
      `${process.env.REACT_APP_ORDERAT}/order/confirmation`,
      {
        storeId: cartData?.state?.cartdata?.state.statetwo.storeId,
        orderId: cartData?.state?.orderId,
        customer: cartData?.state?.cartdata?.state.username,
        phone: cartData?.state?.cartdata?.state?.contactNo,
        language: "en",
        products: cartData?.state?.productsData,
        total: cartData?.state?.availableDiscount?.discountedAmount
          ? cartData?.state?.availableDiscount?.cartTotalAfterDiscount +
            parseFloat(cartData?.state?.availableDiscount?.deliveryFeeAfterDiscount) +
            parseFloat(cartData?.state?.availableDiscount?.taxAfterDiscount)
          : totalPrice +
            parseFloat(newdeliverycharge) +
            parseFloat(cartData?.state?.totaltax),
        tax: `${cartData?.state?.totaltax}`,
        cartTotal: carttotal,
        currency: "$",
        status: "confirmed",
        drop: cartData?.state?.cartdata?.state?.locationName,
        pickup: cartData?.state?.cartdata?.state?.statetwo.pickup,
        paymentRefrence: paymentReference,
        paymentVia: paymentVia,
        pin: cartData?.state?.orderId.substr(-4),
      },
      {
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2MDNiNzBmNzc3MjA2MTQ1MWNmNzdhMzIiLCJkYXRlT2ZSZWdpc3RlcmF0aW9uIjoiMjAyMS0wMi0yOFQxMDozMToxOS4xMTNaIiwidXNlck5hbWUiOiJ0ZXN0LnVzZXIiLCJlbWFpbElkIjoidGVzdHVzZXJAdGVzdC5jb20iLCJmaXJzdE5hbWUiOiJ0ZXN0IiwibGFzdE5hbWUiOiJ1c2VyIiwibWlkZGxlTmFtZSI6IiIsInBhc3N3b3JkIjoidGVzdEAxMjMiLCJyb2xlIjoidGVzdCIsImltYWdlVXJsIjoidGVzdCIsImNyZWF0ZWRfYXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJfX3YiOjB9.N1fVw8a2_YA5LeomrbrM1GPDRc__qtMkOeHFWiCABmg`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const orderbookingtwo = (orderId, data) => {
  // console.log('data',data)
  return axios
    .post(
      `${process.env.REACT_APP_ORDERAT_TWO}/order/booking`,
      {
        orderId: orderId,
        customerName: data.username,
        customerMobileNumber: data.contactNo,
        customerEmail: "",
        orderValue: "",
        orderDetails: "",
        pickupId: "SMASH BURGER",
        dropId: data.locationName,
        storeId: data.storeId,
      },
      {
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2MDNiNzBmNzc3MjA2MTQ1MWNmNzdhMzIiLCJkYXRlT2ZSZWdpc3RlcmF0aW9uIjoiMjAyMS0wMi0yOFQxMDozMToxOS4xMTNaIiwidXNlck5hbWUiOiJ0ZXN0LnVzZXIiLCJlbWFpbElkIjoidGVzdHVzZXJAdGVzdC5jb20iLCJmaXJzdE5hbWUiOiJ0ZXN0IiwibGFzdE5hbWUiOiJ1c2VyIiwibWlkZGxlTmFtZSI6IiIsInBhc3N3b3JkIjoidGVzdEAxMjMiLCJyb2xlIjoidGVzdCIsImltYWdlVXJsIjoidGVzdCIsImNyZWF0ZWRfYXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJfX3YiOjB9.N1fVw8a2_YA5LeomrbrM1GPDRc__qtMkOeHFWiCABmg`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const orderaccept = (cartData) => {
  // let carttotal=totalprice+deliveryCharge
  return axios
    .post(
      `${process.env.REACT_APP_ORDERAT}/order/accept`,
      {
        storeId: "SUBWAY",
        orderId: "12345678910",
        status: "accepted",
      },
      {
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2MDNiNzBmNzc3MjA2MTQ1MWNmNzdhMzIiLCJkYXRlT2ZSZWdpc3RlcmF0aW9uIjoiMjAyMS0wMi0yOFQxMDozMToxOS4xMTNaIiwidXNlck5hbWUiOiJ0ZXN0LnVzZXIiLCJlbWFpbElkIjoidGVzdHVzZXJAdGVzdC5jb20iLCJmaXJzdE5hbWUiOiJ0ZXN0IiwibGFzdE5hbWUiOiJ1c2VyIiwibWlkZGxlTmFtZSI6IiIsInBhc3N3b3JkIjoidGVzdEAxMjMiLCJyb2xlIjoidGVzdCIsImltYWdlVXJsIjoidGVzdCIsImNyZWF0ZWRfYXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJfX3YiOjB9.N1fVw8a2_YA5LeomrbrM1GPDRc__qtMkOeHFWiCABmg`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const ETAtime = (storeId) => {
  // let carttotal=totalprice+deliveryCharge
  return axios
    .post(
      `${process.env.REACT_APP_ORDERAT}/store/eta`,
      {
        storeId: storeId,
      },
      {
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2MDNiNzBmNzc3MjA2MTQ1MWNmNzdhMzIiLCJkYXRlT2ZSZWdpc3RlcmF0aW9uIjoiMjAyMS0wMi0yOFQxMDozMToxOS4xMTNaIiwidXNlck5hbWUiOiJ0ZXN0LnVzZXIiLCJlbWFpbElkIjoidGVzdHVzZXJAdGVzdC5jb20iLCJmaXJzdE5hbWUiOiJ0ZXN0IiwibGFzdE5hbWUiOiJ1c2VyIiwibWlkZGxlTmFtZSI6IiIsInBhc3N3b3JkIjoidGVzdEAxMjMiLCJyb2xlIjoidGVzdCIsImltYWdlVXJsIjoidGVzdCIsImNyZWF0ZWRfYXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJfX3YiOjB9.N1fVw8a2_YA5LeomrbrM1GPDRc__qtMkOeHFWiCABmg`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const getOrderPin = (orderId) => {
  return axios
    .post(
      `${process.env.REACT_APP_ORDERAT}/order/pin`,
      {
        orderId: orderId,
      },
      {
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2MDNiNzBmNzc3MjA2MTQ1MWNmNzdhMzIiLCJkYXRlT2ZSZWdpc3RlcmF0aW9uIjoiMjAyMS0wMi0yOFQxMDozMToxOS4xMTNaIiwidXNlck5hbWUiOiJ0ZXN0LnVzZXIiLCJlbWFpbElkIjoidGVzdHVzZXJAdGVzdC5jb20iLCJmaXJzdE5hbWUiOiJ0ZXN0IiwibGFzdE5hbWUiOiJ1c2VyIiwibWlkZGxlTmFtZSI6IiIsInBhc3N3b3JkIjoidGVzdEAxMjMiLCJyb2xlIjoidGVzdCIsImltYWdlVXJsIjoidGVzdCIsImNyZWF0ZWRfYXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJfX3YiOjB9.N1fVw8a2_YA5LeomrbrM1GPDRc__qtMkOeHFWiCABmg`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const ETAtimetwo = (storeId, orderId) => {
  // let carttotal=totalprice+deliveryCharge
  return axios
    .post(
      `${process.env.REACT_APP_ORDERAT}/store/eta`,
      {
        storeId: storeId,
        orderId: orderId,
      },
      {
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2MDNiNzBmNzc3MjA2MTQ1MWNmNzdhMzIiLCJkYXRlT2ZSZWdpc3RlcmF0aW9uIjoiMjAyMS0wMi0yOFQxMDozMToxOS4xMTNaIiwidXNlck5hbWUiOiJ0ZXN0LnVzZXIiLCJlbWFpbElkIjoidGVzdHVzZXJAdGVzdC5jb20iLCJmaXJzdE5hbWUiOiJ0ZXN0IiwibGFzdE5hbWUiOiJ1c2VyIiwibWlkZGxlTmFtZSI6IiIsInBhc3N3b3JkIjoidGVzdEAxMjMiLCJyb2xlIjoidGVzdCIsImltYWdlVXJsIjoidGVzdCIsImNyZWF0ZWRfYXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJfX3YiOjB9.N1fVw8a2_YA5LeomrbrM1GPDRc__qtMkOeHFWiCABmg`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
