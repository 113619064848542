// import { createStore } from 'redux'
import {ItemCounter,ItemsListReducer,otpNumberReducer, paymentReducer,storeCurrencyReducer,storelanguagereducer,storemapIdreducer,discountDetailsReducer, confirmedCartReducer} from './reducers/index.js'
import { configureStore ,combineReducers } from '@reduxjs/toolkit'
import  thunk  from 'redux-thunk'
// import storage from 'redux-persist/lib/storage';
// import { persistReducer, persistStore } from 'redux-persist';
// const persistConfig = {
//     key: 'root',
//     storage,
//   }

const mutipleReducers = combineReducers({
    IC:ItemCounter,
    ItemsListReducer,
    otpNumberReducer,
    paymentReducer,
    storeCurrencyReducer,
    storelanguagereducer,
    storemapIdreducer,
    discountDetailsReducer,
    confirmedCartReducer
})

// const persistedReducer = persistReducer(persistConfig, mutipleReducers)
let initialState={}
export const store = configureStore({
    reducer:mutipleReducers,
    middleware:[thunk],
    preloadedState:initialState
})

// export const persistor = persistStore(store)