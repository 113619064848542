import logo from "./logo.svg";
import "./App.css";
import { Navigate, Route, Routes, redirect } from "react-router-dom";
import Home from "./components/Home";
import "../src/CSS/custom.css";
import ItemsList from "./components/ItemsList";
import Details from "./components/Details";
import Cart from "./components/Cart";
import Congratulations from "./components/Congratulations";
import Checkout from "./stripepayment/Checkout";
import Help from "./components/Help";
import MoreDetails from "./components/MoreDetails";
import HomeSecond from "./components/HomeSecond";
import ItemsListTwo from "./components/ItemListTwo";
import SelectDelivery from "./components/SelectDelivery";
import StoreList from "./components/StoreList";
import Productlist from "./components/Productlist";
import Payment from "./stripepayment/Payment";
import Redirecting from "./components/Redirecting";
import BillDetails from "./components/BillDetails";
import Redirect from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import Search from "./components/Search";
import { getSiteDetails } from "./API";

function App() {
  const [siteDetails, setSiteDetails] = useState();
  const location = useLocation()

  function hexToRgb(hex) {
    if(!hex){
      return
    }
    hex = hex.replace(/^#/, '');
    var r = parseInt(hex.substring(0, 2), 16);
    var g = parseInt(hex.substring(2, 4), 16);
    var b = parseInt(hex.substring(4, 6), 16);
    return [r, g, b];
  }

  useEffect(() => {
    !siteDetails &&
    getSiteDetails(process.env.REACT_APP_SITE_NAME)
    .then((res) => {
      setSiteDetails(res.data.message);
      // console.log(res.data.message)
    })
    .catch((err) => {
      console.log(err);
    });
    document.documentElement.style.setProperty("--theme-primary", siteDetails?.primaryColor)
    document.documentElement.style.setProperty("--theme-primary-hex", hexToRgb(siteDetails?.primaryColor))
    if(location.pathname.includes("storelist")) {
      document.body.className = "no__scroll"
    } else {
      document.body.className = ""
    }
    // document.html.class = "no__scroll"
}, [location.pathname,siteDetails]);

  return (
    <div className="App">
      <Routes>
        {process.env.REACT_APP_CHANGE_HOME ? (
          <Route path="/" element={<StoreList />} />
        ) : (
          <>
            <Route path="/" element={<Home />} />
            <Route path="/storelist" element={<StoreList />} />
          </>
        )}
        {/* <Route path="/" element={<Home/>}/>  */}
        <Route path="/home" element={<Home />} />
        <Route path="/homesecond" element={<HomeSecond />} />
        <Route path="/Itemslist" element={<ItemsList />} />
        <Route path="/ItemslistTwo" element={<ItemsListTwo />} />
        <Route path="/details" element={<Details />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/congratulations" element={<Congratulations />} />
        <Route path="/checkout" element={<Payment />} />
        <Route path="/help" element={<Help />} />
        <Route path="/moredetails" element={<MoreDetails />} />
        <Route path="/selectDetails" element={<SelectDelivery />} />
        <Route path="/storelist" element={<StoreList />} />
        <Route path="storelist/:id" element={<Productlist />} />
        <Route path="/redirecting" element={<Redirecting />} />
        <Route path="/billdetails" element={<BillDetails />} />
        <Route path="/search" element={<Search />} />
        {/* <Route path="*" element={<Navigate to="/"/>}  />   */}
      </Routes>
    </div>
  );
}

export default App;
