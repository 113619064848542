import { currencyConstant, discountConstant, languageConstant, mapIdConstant } from "../Constants";

const counterInitialState = {
  counter: 0,
};

export const ItemCounter = (
  state = { cart: [], totalitems: 0, totalPrice: 0 },
  action
) => {
  switch (action.type) {
    case "INCREMENT_COUNT":
    case "DECREMENT_COUNT":
    case "VARIANT_COUNT":
    case "ITEM_REMOVED_FROM_CART":
      return {
        ...state,
        cart: [...action.payload.cart],
        totalitems: action.payload.totalitems,
        totalPrice: action.payload.totalPrice,
      };
    // case 'DECREMENT_COUNT':
    //     return {
    //         ...state,
    //         cart: [...action.payload.cart],
    //         totalitems: action.payload.totalitems,
    //         totalPrice: action.payload.totalPrice
    //     }

    // case 'VARIANT_COUNT':
    //     return {
    //         ...state,
    //         cart: [...action.payload.cart],
    //         totalitems: action.payload.totalitems,
    //         totalPrice: action.payload.totalPrice
    //     }

    case "CLEAR_CART":
      return {
        ...state,
        cart: [],
        totalitems: 0,
        totalPrice: 0,
      };

    default:
      return state;
  }
};

export const confirmedCartReducer = (state = { confirmedCart: [], confirmedTotalItems: 0, confirmedTotalPrice: 0 },
  action) => {
    switch (action.type) {
      case "CONFIRMED_CART":
        return {
          ...state,
          confirmedCart: [...action.payload.confirmedCart],
          confirmedTotalItems: action.payload.confirmedTotalItems,
          confirmedTotalPrice: action.payload.confirmedTotalPrice,
        }
    
      case "CLEAR_CONFIRMED_CART":
          return {
            ...state,
            confirmedCart: [],
            confirmedTotalItems: 0,
            confirmedTotalPrice: 0,
          };
    
      default:
          return state;
    }
  }

const itemslist = [];
export const ItemsListReducer = (state = itemslist, action) => {
  switch (action.type) {
    case "ITEMS_LIST_STORING":
      // console.log('state', state, itemslist)
      return { ...state, itemslist: action.payload };

    default:
      return state;
  }
};

const otpnumber = "";
export const otpNumberReducer = (state = itemslist, action) => {
  switch (action.type) {
    case "OTP_NUMBER":
      return { ...state, otpnumber: action.payload };

    default:
      return state;
  }
};

export const paymentReducer = (state = { Payment_refrence: {} }, action) => {
  switch (action.type) {
    case "Payment_Refrence":
      return {
        ...state,
        Payment_refrence: action.payload,
      };
    default:
      return state;
  }
};

export const discountDetailsReducer = (state = { discountDetails: {} }, action) => {
  switch (action.type) {
      case discountConstant.STORE_DISCOUNT:
          return {
             ...state,
              discountDetails:action.payload
          }
      default:
          return state;
      }
  }

export const storeCurrencyReducer = (state = {}, action) => {
  switch (action.type) {
    case currencyConstant.STORE_CURRENCY:
      return {
        ...state,
        storeCurrency: action.payload,
      };
    default:
      return state;
  }
};

export const storelanguagereducer = (
  state = {
    storelanguage: JSON.parse(process.env.REACT_APP_STORE_DEFAULT_LANGUAGE)
      ? JSON.parse(process.env.REACT_APP_STORE_DEFAULT_LANGUAGE)
      : {
        "name": "English",
        "code": "en-US",
        "flag": "https://imagedelivery.net/HOqw_0sYZTUgFQ-soBOgtg/b5ee3144-3b2a-4397-f0fd-5cc570ce0c00/public"
      },
  },
  action
) => {
  switch (action.type) {
    case languageConstant.STORE_LANGUAGE:
      return {
        ...state,
        storelanguage: action.payload,
      };
    default:
      return state;
  }
};

export const storemapIdreducer = (state = {}, action) => {
  switch (action.type) {
    case mapIdConstant.STORE_MAPID:
      return {
        ...state,
        mapId: action.payload,
      };
    default:
      return state;
  }
};
