import { PaymentElement } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import {
  useStripe,
  useElements,
  CardElement,
  CardCvcElement,
  CardNumberElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { orderconfirm } from "../API";
import { useDispatch, useSelector } from "react-redux";
import { clearCart, confirmedCart } from "../redux/Actions";
import { io } from 'socket.io-client';

const url = "http://localhost:4000/"
const socket =  io(process.env.REACT_APP_ORDERAT);

export default function CheckoutForm(props) {
  const { cart, totalitems, totalPrice } = useSelector((state) => state.IC);
  const { storelanguage } = useSelector((state) => state.storelanguagereducer);
  const toastmsg = (msg) => {
    toast(msg);
  };
  const stripe = useStripe();
  const elements = useElements();
  let CartData = props.cartData.state;
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userAgent = window.navigator.userAgent;
  // const platform = window.navigator.platform;
  const platform = userAgent.match(/(Windows|Mac|Linux|Android|iPhone|iPad|iPod)/i);
  const randomString = Math.random().toString(20).substring(2, 14) + Math.random().toString(20).substring(2, 14);

  const deviceID = `${userAgent}-${platform}-${randomString}`;
  // console.log(deviceID)

  useEffect(() => {
    socket.auth = {userId: deviceID}; 
    socket.connect(`${process.env.REACT_APP_ORDERAT}`)
  },[socket])

  const handleSubmit = async (event) => {
    // console.log('clientSecret', props.clientSecret)
    event.preventDefault();
    const { paymentIntent, error } = await stripe.confirmCardPayment(
      props.clientSecret,
      {
        payment_method: {
          card: elements.getElement(CardNumberElement),
          billing_details: {
            name: CartData?.cartdata?.state?.username,
            address: {
              line1: "123 Main St",
              city: "City",
              state: "State",
              postal_code: "12345",
              country: "US",
            },
          },
        },
      }
    );

    if (error) {
      // console.log('Payment failed:', error.message);
      if (error.code === "incomplete_number") {
        toast.error(
          props?.cartData?.state?.statetwo?.toastTexts?.cardIncompleteNumber[
            storelanguage?.code
          ]
        );
      } else if (error.code === "incomplete_expiry") {
        toast.error(
          props?.cartData?.state?.statetwo?.toastTexts?.cardIncompleteExpiry[
            storelanguage?.code
          ]
        );
      } else if (error.code === "incomplete_cvc") {
        toast.error(
          props?.cartData?.state?.statetwo?.toastTexts?.cardIncompleteCVC[
            storelanguage?.code
          ]
        );
      } else if (error.code === "invalid_number") {
        toast.error(
          props?.cartData?.state?.statetwo?.toastTexts?.cardInvalidNumber[
            storelanguage?.code
          ]
        );
      } else if (error.code === "invalid_expiry") {
        toast.error(
          props?.cartData?.state?.statetwo?.toastTexts?.cardInvalidExpiry[
            storelanguage?.code
          ]
        );
      } else if (error.code === "invalid_expiry_year") {
        toast.error(
          props?.cartData?.state?.statetwo?.toastTexts?.cardInvalidExpiryYear[
            storelanguage?.code
          ]
        );
      } else if (error.code === "invalid_cvc") {
        toast.error(
          props?.cartData?.state?.statetwo?.toastTexts?.cardInvalidCVC[
            storelanguage?.code
          ]
        );
      } else if (error.code === "invalid_expiry_year_past") {
        toast.error(
          props?.cartData?.state?.statetwo?.toastTexts
            ?.cardInvalidExpiryYearPast[storelanguage?.code]
        );
      } else if (error.code === "card_declined") {
        toast.error(
          props?.cartData?.state?.statetwo?.toastTexts?.cardDeclined[
            storelanguage?.code
          ]
        );
      } else {
        toast.error(error.message);
      }
      // console.error("Order Payment", error);
    } else {
      // Payment successful, retrieve payment reference from paymentIntent
      const paymentReference = paymentIntent.id;
      // console.log('Payment reference:', paymentReference);
      if (paymentIntent.status === "succeeded") {
        // console.log('props.cardData',props.cartData)

        orderconfirm(
          props.cartData,
          "Stripe",
          paymentReference,
          cart,
          totalPrice,
          storelanguage?.name
        )
          .then((res) => {
            // toast.success(res.data.message);
            toast.success(
              props?.cartData?.state?.statetwo?.toastTexts
                ?.orderConfirmedSuccess[storelanguage?.code]
            );
            // console.log("Order Confirm Success", res.data)
            dispatch(confirmedCart(cart, totalitems, totalPrice));
            dispatch(clearCart());

            socket.emit('orderConfirmed', {roomId: props?.cartData?.state?.statetwo?.storeId, orderId: CartData.orderId})

            navigate("/congratulations", { state: props.cartData });
            localStorage.clear();
            sessionStorage.clear();

          })
          .catch((err) => {
            toast.error(
              props?.cartData?.state?.statetwo?.toastTexts?.orderConfirmedError[
                storelanguage?.code
              ]
            );
            console.error("Order Confirm Error", err.data);
          });
      }
      // Process payment reference or perform other actions
    }
  };
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   if (!stripe || !elements) {
  //     // Stripe.js has not yet loaded.
  //     // Make sure to disable form submission until Stripe.js has loaded.
  //     return;
  //   }
  //   setIsProcessing(true);

  //   const { error } = await stripe.confirmPayment({
  //     elements,
  //     confirmParams: {
  //       // Make sure to change this to your payment completion page
  //       return_url: `${window.location.origin}/congratulations` ,
  //     },
  //   },
  //   {
  //    metadata:props.cartData.state
  //   }
  //   ).then(function(result) {
  //     if (result.error) {
  //       // Inform the customer that there was an error.
  //       console.log(result.error.message);
  //     } else {
  //       // Handle next step based on PaymentIntent's status.

  //       console.log("PaymentIntent ID: " + result.paymentIntent.id);
  //       console.log("PaymentIntent status: " + result.paymentIntent.status);
  //     }
  //   })

  //   if (error.type === "card_error" || error.type === "validation_error") {
  //     setMessage(error.message);
  //   } else  {
  //     setMessage("An unexpected error occured.");
  //   }

  //   setIsProcessing(false);
  // };

  return (
    <>
      <form id="payment-form" onSubmit={handleSubmit}>
        {/* {console.log('props.cartData',props.cartData)} */}
        {/* <PaymentElement id="payment-element" /> */}
        {/* <CardElement /> */}
        <div className="checkout_page_card_element_wrapper_div">
          <p>
            {
              props?.cartData?.state?.statetwo?.paymentTexts?.cardNumber[
                storelanguage?.code
              ]
            }
          </p>
          {/* {storelanguage?.name === "English" ? (
            <p>Card Number</p>
          ) : (
            <p>Número de tarjeta</p>
          )} */}
          <CardNumberElement
            className="checkout_page_card_element"
            placeholder="1234 1234 1234 1234"
            id="card_number"
          />
        </div>
        <div className="checkout_page_card_element_wrapper">
          <div className="checkout_page_card_element_wrapper_div">
            <p>
              {
                props?.cartData?.state?.statetwo?.paymentTexts?.cardExpiry[
                  storelanguage?.code
                ]
              }
            </p>
            {/* {storelanguage?.name === "English" ? (
              <p>Card Expiry</p>
            ) : (
              <p>Caducidad de la tarjeta</p>
            )} */}
            <CardExpiryElement
              type="text"
              className="checkout_page_card_element"
              id="card_expire_date"
            />
          </div>
          <div className="checkout_page_card_element_wrapper_div">
            <p>
              {
                props?.cartData?.state?.statetwo?.paymentTexts?.cardCVC[
                  storelanguage?.code
                ]
              }
            </p>
            {/* {storelanguage?.name === "English" ? <p>Card CVC</p> : <p>CVC</p>} */}
            <CardCvcElement
              className="checkout_page_card_element"
              placeholder="123"
              type="text"
            />
          </div>
        </div>
        {/* <button className="Stripe_payment_button" disabled={isProcessing || !stripe || !elements} id="submit">
        <span id="button-text">
          {isProcessing ? "Processing ... " : "Pay now"}
        </span>
      </button> */}
        <button className="Stripe_payment_button" type="submit">
          {
            props?.cartData?.state?.statetwo?.paymentTexts?.pay[
              storelanguage?.code
            ]
          }
          {/* {storelanguage?.name === "English" ? "Pay" : "Pagar"} */}
        </button>
        <img
          className="PowredBY_stripe"
          src="/assets/images/stripe-powered.svg"
          alt=""
        />
        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
      </form>
    </>
  );
}
