import { useLocation, useNavigate } from "react-router-dom";
import {
  DecrementCount,
  IncrementCount,
  clearCart,
  discountDetails,
  removeItemFromCart,
  storecurrency,
} from "../redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import { Fragment, useEffect } from "react";
import { useState } from "react";
import { Box, Modal } from "@mui/material";
import { getSiteDetails, getstorelist } from "../API";
import { RxCross2 } from "react-icons/rx";
import { toast } from "react-toastify";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import BillDetailsHeader from "./BillDetailsHeader";
import Footer from "./Footer";
import Header from "./Header";
import { VscClearAll } from "react-icons/vsc";
import { remove } from "lodash";
import { currencyFormatter } from "../utils";

function BillDetails() {
  const [refresh, setrefresh] = useState(false);
  const [totalprice, settotalprice] = useState(0);
  const [deliveryCharge, setDeliveryCharge] = useState(0);
  const [open, setOpen] = useState(false);
  const [mergedCartWithSameProduct, setMergedCartWithSameProduct] = useState(
    []
  );
  const [storeDiscount, setStoreDiscount] = useState(null);
  const [siteDetails, setSiteDetails] = useState();

  const navigate = useNavigate();
  const billdata = useLocation();
  const dispatch = useDispatch();

  const { storeCurrency } = useSelector((state) => state.storeCurrencyReducer);
  const { storelanguage } = useSelector((state) => state.storelanguagereducer);
  const { cart, totalitems, totalPrice } = useSelector((state) => state.IC);
  const [discountBannerValue, setDiscountBannerValue] = useState(
    process.env.REACT_APP_MIN_CART_VALUE
  );
  // if (
  //   !Array.isArray(cart) &&
  //   typeof cart === "object" &&
  //   localStorage.getItem("persist:root")
  // ) {
  //   localStorage.setItem(
  //     "persist:root",
  //     '{"IC":"{\\"cart\\":[],\\"totalitems\\":0,\\"totalPrice\\":0}","ItemsListReducer":"[]","otpNumberReducer":"[]","paymentReducer":"{\\"Payment_refrence\\":{}}","storeCurrencyReducer":"{}","storelanguagereducer":"{\\"storelanguage\\":\\"English\\"}","storemapIdreducer":"{}","_persist":"{\\"version\\":-1,\\"rehydrated\\":true}"}'
  //   );
  //   window.location.reload(false);
  // }

  let totaltax = 0;

  const handleOpen = () => setOpen(true);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 350,
    bgcolor: "#ffffff",
    boxShadow: 24,
    p: 4,
    borderRadius: "10px",
    paddingTop: "11px",
    border: "none",
    paddingRight: "0px",
    paddingLeft: "0px",
    zIndex: "999999999999999999999999",
  };

  useEffect(() => {
    // console.log('billdata', billdata)
    setDeliveryCharge(billdata?.state?.statetwo?.deliveryfee);
    let totalpricearr = [];
    billdata?.state?.stateone.map((item) => {
      totalpricearr.push(item.price * item.count);
      return null;
    });
    settotalprice(totalpricearr.reduce((a, b) => a + b, 0));
  }, [refresh]);

  useEffect(() => {
    dispatch(storecurrency(billdata?.state?.statetwo?.currency))
  },[])

  useEffect(() => {
    if (
      !billdata.state ||
      billdata.state === undefined ||
      billdata.state === null ||
      billdata.state === "null"
    ) {
      window.location.replace("/");
    }
  }, []);

  // For future reference
  useEffect(() => {
    //  if(cart && cart.length > 0) {
    //   const tempCart = JSON.parse(JSON.stringify(cart))
    //   const mergedCart = [];
    //   tempCart.map((cartItem) => {
    //     const key = Object.keys(cartItem)[0];
    //     const productExists = mergedCart.find((item) => item[key]);
    //     const mergedCartItems = []
    //     if (productExists) {
    //       // Merge the product data
    //       productExists[key].push(...cartItem[key]);
    //     } else {
    //       // If no existing object found, add it to the merged array
    //       mergedCart.push(cartItem);
    //     }
    //     return null;
    //   });
    //   console.log(mergedCart, "MERGED ARRAY")
    //   // const outputObject = {};
    //   // mergedCart.forEach((item) => {
    //   //   for (const key in item) {
    //   //     if (outputObject[key] === undefined) {
    //   //       outputObject[key] = item[key];
    //   //     } else if (typeof outputObject[key] === 'object') {
    //   //       // Handle nested objects by recursively merging them
    //   //       outputObject[key] = mergeObjects(outputObject[key], item[key]);
    //   //     } else {
    //   //       outputObject[key] += item[key];
    //   //     }
    //   //   }
    //   // });
    //   // function mergeObjects(obj1, obj2) {
    //   //   for (const key in obj2) {
    //   //     if (obj1[key] === undefined) {
    //   //       obj1[key] = obj2[key];
    //   //     } else if (typeof obj1[key] === 'object') {
    //   //       obj1[key] = mergeObjects(obj1[key], obj2[key]);
    //   //     } else {
    //   //       obj1[key] += obj2[key];
    //   //     }
    //   //   }
    //   //   return obj1;
    //   // }
    //   // console.log([outputObject]);
    //   // const duplicateVariantCounts = {};
    //   // const outputObject = [];
    //   // mergedCart.map((cartItem) => {
    //   //   for (const key in cartItem) {
    //   //     if (typeof cartItem[key] === 'object' && cartItem[key].length > 0) {
    //   //       // Handle objects with nested key-value pairs
    //   //       cartItem[key].map((variants) => {
    //   //         for (const variant in variants) {
    //   //           console.log(variant, "VARIANT")
    //   //           console.log(variants[variant])
    //   //           if (duplicateVariantCounts[variant] === undefined) {
    //   //             duplicateVariantCounts[variant] = variants[variant];
    //   //           } else {
    //   //             duplicateVariantCounts[variant] += variants[variant];
    //   //           }
    //   //         }
    //   //         return null;
    //   //       });
    //   //     } else {
    //   //       // Handle objects with single key-value pairs
    //   //       if (duplicateVariantCounts[key] === undefined) {
    //   //         duplicateVariantCounts[key] = cartItem[key];
    //   //       } else {
    //   //         duplicateVariantCounts[key] += cartItem[key];
    //   //       }
    //   //     }
    //   //   }
    //   //   return null;
    //   // });
    //   // console.log(duplicateVariantCounts)
    //   // Create the desired output object
    //   // const outputObject = [];
    //   // for (const key in duplicateVariantCounts) {
    //   //   const newObj = {};
    //   //   newObj[key] = duplicateVariantCounts[key];
    //   //   outputObject.push(newObj);
    //   // }
    //   // console.log(outputObject)
    //   // setMergedCartWithSameProduct(mergedCart)
    //   // cart.map(cartItem => {
    //   //   return null;
    //   // })
    //  }
  }, []);

  useEffect(() => {
    getstorelist().then((res) => {
      // console.log('res.data.message', res.data.message)
      res.data.message.map((item) => {
        if (item.storeId === billdata?.state?.statetwo.storeId) {
          if (item.status === 1) {
            dispatch(discountDetails(item.topDiscount));
            if (item?.minCartValue) {
              setDiscountBannerValue(item?.minCartValue);
            }
            // console.log(item, "Store details")
          }
        }
        return null;
      });
    });
  }, []);

  useEffect(() => {
    getSiteDetails(process.env.REACT_APP_SITE_NAME)
      .then((res) => {
        setSiteDetails(res?.data?.message);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleproceedbutton = () => {
    // StoreStatus("/selectDetails", handleOpen, { stateone: billdata?.state?.stateone, statetwo: billdata?.state?.statetwo ,totalPrice:totalprice?.toFixed(2), deliveryCharge:deliveryCharge , totaltax:totaltax?.toFixed(2) })
    if (cart.length === 0 || parseFloat(totalPrice) === 0) {
        toast.error(`${billdata?.state?.statetwo?.billDetailsTexts?.cartEmpty[storelanguage?.code]}`);
    } else {
      // console.log(discountBannerValue,"discountBannerValue")
      if (discountBannerValue && totalPrice < discountBannerValue) {
        toast.error(
          `Minimum order amount is ${currencyFormatter(
            discountBannerValue,
            storeCurrency
          )}. Please add more items.`
        );
        return;
      }
      getstorelist()
        .then((res) => {
          // console.log('res.data.message', res.data.message)
          res.data.message.map((item) => {
            if (item.storeId === billdata?.state?.statetwo.storeId) {
              if (item.status === 0) {
                handleOpen();
              } else if (item.status === 1) {
                navigate("/selectDetails", {
                  state: {
                    stateone: billdata?.state?.stateone,
                    statetwo: billdata?.state?.statetwo,
                    totalPrice: totalprice?.toFixed(2),
                    deliveryCharge: deliveryCharge,
                    totaltax: totaltax?.toFixed(2),
                  },
                });

                // console.log(item, "Store DEtails");
              }
            }
            return null;
          });
        })
        .catch((err) => {});
    }
  };

  const handleClose = (reason) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
  };

  const itemsCount = (cartItem) => {
    if (Array.isArray(Object.values(cartItem)[0])) {
      // return cartItem[0].product.image
      return "";
    } else {
      return Object.values(cartItem)[0];
    }
  };

  const variantCount = (cartItem) => {
    if (Array.isArray(Object.values(cartItem)[0])) {
      let quantityCount = Object.values(cartItem)[0].map(
        (variantItem, index) => {
          return 1;
        }
      );
      return quantityCount;
      // return (
      //   <>
      //     {Object.values(cartItem)[0].map((variantItem, index) => {
      //       return "1";
      //     })}
      //   </>
      // );
    }
    // else if(!Array.isArray(Object.values(cartItem)[0])) {

    // }
    else {
      return `${Object.values(cartItem)[0]}`;
    }
  };

  const deleteCartItemVariant = (product, cartItem) => {
    let productCount = 0;
    cart.map((cartObj, index) => {
      if (Object.keys(cartObj)[0] === product._id) {
        ++productCount;
      }
      return null;
    });
    dispatch(removeItemFromCart(product, cart, totalPrice, productCount));
  };

  const deleteCartItem = (product, cartItem) => {
    dispatch(
      removeItemFromCart(product, cart, totalPrice, variantCount(cartItem))
    );
  };

  // const variantCount = (cartItem) => {
  //   if (Array.isArray(Object.values(cartItem)[0])) {
  //     return (
  //       <>
  //         {Object.values(cartItem)[0].map((variantItem, index) => {
  //           return (
  //             <span
  //               key={"variant__item__" + index}
  //               className="Bill_Details_content_itemcount"
  //             >
  //               {variantItem?.productVariant?.name} x 1
  //             </span>
  //           );
  //         })}
  //       </>
  //     );
  //   } else {
  //     return (
  //       <span className="Bill_Details_content_itemcount">
  //         x {Object.values(cartItem)[0]}
  //       </span>
  //     );
  //   }
  // };

  const CalculateVariantsQuantity = ({ cartItems }) => {
    let filteredCartItems = cartItems.reduce((uniqueVariant, variant) => {
      if (!uniqueVariant[Object.keys(variant)[0]]) {
        let uniqueVariantObject = Object.keys(variant).includes(
          "multipleVariants"
        )
          ? {
              variantCount: 1,
              product: Object.values(variant)[1],
              productVariant: Object.values(variant)[2],
              multipleVariants: Object.values(variant)[3],
            }
          : {
              variantCount: 1,
              product: Object.values(variant)[1],
              productVariant: Object.values(variant)[2],
            };
        uniqueVariant[Object.keys(variant)[0]] = uniqueVariantObject;
      } else if (uniqueVariant[Object.keys(variant)[0]]) {
        uniqueVariant[Object.keys(variant)[0]].variantCount += 1;
      }

      return uniqueVariant;
    }, []);

    return (
      <div className="flex flex-col gap-2">
        {Object.values(filteredCartItems).length > 0 &&
          Object.values(filteredCartItems).map((cartItem, index) => (
            <div
              key={"filtered__cart__item__no__" + index}
              // className="flex flex-col gap-1"
              className="grid grid-cols-[50%_50%] items-center"
            >
              <span className="text-[12px] font-medium capitalize">
                {cartItem?.multipleVariants?.length > 0 ? (
                  <>
                    {cartItem.multipleVariants
                      .map((variant) => variant?.name[storelanguage?.code])
                      .join(", ")
                      .toLowerCase()}
                  </>
                ) : (
                  <>
                    {cartItem.productVariant?.name[
                      storelanguage?.code
                    ].toLowerCase()}
                  </>
                )}
              </span>
              <div className="cart__product__list__container">
                <div className="cart__product__list__wrapper">
                  {/* <div
                    key={"variant__increment__modal__variant__div__" + index}
                    className="!w-full shadow-none variant__increment__modal__variant__add__btn__wrapper border-none"
                    // className="Productlist_product_counter_wrapper"
                  > */}
                  <button
                    className="ItemsListPage-ItemsList-Counter-btn !py-[5px]"
                    onClick={() => {
                      dispatch(
                        DecrementCount(
                          cartItem.product,
                          cart,
                          totalPrice,
                          cartItem.product?.variant?.length !== 0
                            ? {
                                product: cartItem.product,
                                variantProduct: cartItem.productVariant,
                                multipleVariants:
                                  cartItem?.multipleVariants?.length > 0
                                    ? cartItem?.multipleVariants
                                    : undefined,
                              }
                            : undefined
                        )
                      );
                    }}
                  >
                    {" "}
                    -{" "}
                  </button>
                  <input
                    type="number"
                    style={{
                      WebkitAppearance: "none",
                      margin: 0,
                      color: "var(--theme-primary)",
                      minWidth: "10px",
                    }}
                    readOnly
                    value={cartItem.variantCount}
                    className="ItemsListPage-ItemsList-Counter-input"
                  />
                  <button
                    className="ItemsListPage-ItemsList-Counter-btn !py-[5px]"
                    onClick={() => {
                      dispatch(
                        IncrementCount(
                          cartItem.product,
                          cart,
                          totalPrice,
                          cartItem.product?.variant?.length !== 0
                            ? {
                                product: cartItem.product,
                                variantProduct: cartItem.productVariant,
                                multipleVariants:
                                  cartItem?.multipleVariants?.length > 0
                                    ? cartItem?.multipleVariants
                                    : undefined,
                              }
                            : undefined
                        )
                      );
                    }}
                  >
                    {" "}
                    +{" "}
                  </button>{" "}
                  {/* </div> */}
                </div>
                <span className="cart__order__price !font-normal !text-[14px]">
                  {currencyFormatter(
                    (cartItem.product.price * cartItem.variantCount).toFixed(2),
                    storeCurrency
                  )}
                  {/* {(cartItem.product.price * cartItem.variantCount).toFixed(2)} */}
                </span>
              </div>
            </div>
          ))}
      </div>
    );
  };

  return (
    <div className="MainWhole-wrapper-container">
      <Header billdata={billdata.state?.statetwo} siteDetails={siteDetails} />
      <div className="DetailsPage-Main-wrapper">
        <div className="d_flex_center">
          <h2>
            {
              billdata?.state?.statetwo?.billDetailsTexts?.productsTitle[
                storelanguage?.code
              ]
            }
          </h2>
          {/* {storelanguage?.name === "English" ? (
            <h2> My Products </h2>
          ) : (
            <h2> Mis productos </h2>
          )} */}

          {/* <img
            src="/assets/images/edit-cart.svg"
            className="EditIcon_image"
            onClick={() => {
              navigate(-1);
            }}
            style={{ cursor: "pointer" }}
            alt=""
          /> */}
        </div>
        <div className="DetailsPage-content-wrapper">
          {cart && cart.length > 0 ? (
            <>
              {cart
                .filter(
                  (cartObj, index) =>
                    cart.findIndex(
                      (item) => Object.keys(item)[0] === Object.keys(cartObj)[0]
                    ) === index
                )
                .map((cartItem, index) => {
                  return (
                    <Fragment key={"cart__item__no__" + index}>
                      {!Array.isArray(Object.values(cartItem)[0]) ? (
                        <div
                          key={"cartItem" + index}
                          className="mb-2 ItemsListPage-ItemsList-containers"
                        >
                          <>
                            <div className="h-full product-content-wrapper">
                              {/* <span
                                className="cart__order__delete"
                                onClick={() =>
                                  deleteCartItem(cartItem.product, cartItem)
                                }
                              >
                                <RxCross2 className="cart__order__delete__icon" />
                              </span> */}
                              {/* <div className="product-content-wrapper-inner-div-one">
                                <img
                                  src={
                                    Array.isArray(Object.values(cartItem)[0])
                                      ? Object.values(cartItem)[0][0].product
                                          .image
                                      : cartItem.product?.image
                                  }
                                  className="product-images-class"
                                  alt=""
                                />
                              </div> */}
                              <div className="product-content-wrapper-inner-div-two">
                                <h4 className="!text-[14px] !leading-5 !line-clamp-2">
                                  {" "}
                                  {Array.isArray(Object.values(cartItem)[0])
                                    ? `${
                                        Object.values(cartItem)[0][0].product
                                          ?.name[storelanguage?.code]
                                      } - ${
                                        Object.values(cartItem)[0][0]
                                          .productVariant?.name[
                                          storelanguage?.code
                                        ]
                                      }`
                                    : cartItem?.product?.name[
                                        storelanguage?.code
                                      ]}
                                </h4>
                                {/* <span className="Bill_Details_content_itemcount"> */}
                                {/* x {cartItem.count} */}
                                {/* {variantCount(cartItem)} */}
                                {/* </span> */}
                                {/* <div className='Bill_Details_content_itemcount'> <h3>  </h3></div>  */}
                              </div>
                            </div>
                            <div className="cart__product__list__container">
                              <div className="cart__product__list__wrapper">
                                {cart && (
                                  <>
                                    <button
                                      className="ItemsListPage-ItemsList-Counter-btn !py-[5px]"
                                      onClick={() => {
                                        // console.log(cartItem.product, "CART ITEM PRODUCT")
                                        // console.log(cartItem, "CART ITEM")
                                        let variantDetails =
                                          Object.values(cartItem)[0];

                                        if (
                                          typeof variantDetails === "number"
                                        ) {
                                          variantDetails = undefined;
                                        }
                                        // console.log("variant Details", variantDetails)
                                        dispatch(
                                          DecrementCount(
                                            cartItem.product,
                                            cart,
                                            totalPrice,
                                            variantDetails
                                          )
                                        );
                                      }}
                                    >
                                      {" "}
                                      -{" "}
                                    </button>
                                    <input
                                      type="number"
                                      style={{
                                        WebkitAppearance: "none",
                                        margin: 0,
                                        color: "var(--theme-primary)",
                                        minWidth: "20px",
                                      }}
                                      readOnly
                                      // value={calculateQuantity(cartItem.product, cart)}
                                      value={variantCount(cartItem)}
                                      className="ItemsListPage-ItemsList-Counter-input"
                                    />
                                    <button
                                      className="ItemsListPage-ItemsList-Counter-btn !py-[5px]"
                                      onClick={() => {
                                        let variantDetails =
                                          Object.values(cartItem)[0];

                                        if (
                                          typeof variantDetails === "number"
                                        ) {
                                          variantDetails = undefined;
                                        }
                                        // console.log("variant Details", variantDetails)

                                        dispatch(
                                          IncrementCount(
                                            cartItem.product,
                                            cart,
                                            totalPrice,
                                            variantDetails
                                          )
                                        );
                                      }}
                                    >
                                      {" "}
                                      +{" "}
                                    </button>{" "}
                                  </>
                                )}
                              </div>
                              <span className="cart__order__price !font-normal !text-[14px]">
                                {Array.isArray(Object.values(cartItem)[0])
                                  ? currencyFormatter(
                                      Object.values(
                                        cartItem
                                      )[0][0]?.product?.price.toFixed(2),
                                      storeCurrency
                                    )
                                  : currencyFormatter(
                                      (
                                        cartItem.product?.price *
                                        itemsCount(cartItem)
                                      ).toFixed(2),
                                      storeCurrency
                                    )}
                              </span>
                            </div>
                          </>
                        </div>
                      ) : (
                        <div
                          key={"cartItem" + index}
                          className={`px-[10px] ${
                            index !==
                            cart.filter(
                              (cartObj, index) =>
                                cart.findIndex(
                                  (item) =>
                                    Object.keys(item)[0] ===
                                    Object.keys(cartObj)[0]
                                ) === index
                            ).length -
                              1
                              ? "border-b-[#ddd] border-b-[2px] border-solid pb-[12px] mb-2"
                              : ""
                          } items-center flex ${
                            index !== 0 ? "pt-[1rem]" : ""
                          }`}
                        >
                          <div className="h-full product-content-wrapper">
                            {/* <span
                              className="cart__order__delete"
                              onClick={() => {
                                deleteCartItemVariant(
                                  Object.values(cartItem)[0][0].product,
                                  cartItem
                                );
                              }}
                            >
                              <RxCross2 className="cart__order__delete__icon" />
                            </span> */}
                            {/* <div className="product-content-wrapper-inner-div-one">
                              <img
                                src={
                                  Array.isArray(Object.values(cartItem)[0])
                                    ? Object.values(cartItem)[0][0].product
                                        .image
                                    : cartItem.product?.image
                                }
                                className="product-images-class"
                                alt=""
                              />
                            </div> */}
                            <div className="gap-2 product-content-wrapper-inner-div-two">
                              <h4 className="!text-[14px] !leading-5 !line-clamp-2">
                                {
                                  Object.values(cartItem)[0][0].product?.name[
                                    storelanguage?.code
                                  ]
                                }
                              </h4>
                              <div className="mb-0 variant__increment__modal__variant__details__wrapper">
                                <div className="variant__increment__modal__variant__wrapper">
                                  {cart && (
                                    <CalculateVariantsQuantity
                                      key={
                                        "calculate__variant__quantity__" + index
                                      }
                                      cartItems={cart.reduce(
                                        (newCartItem, oldCartItem) => {
                                          if (
                                            Array.isArray(
                                              Object.values(oldCartItem)[0]
                                            ) &&
                                            Object.keys(cartItem)[0] ===
                                              Object.keys(oldCartItem)[0]
                                          ) {
                                            newCartItem.push(
                                              Object.values(oldCartItem)[0][0]
                                            );
                                          }
                                          return newCartItem;
                                        },
                                        []
                                      )}
                                      // setIncrementVariantsModal={setIncrementVariantsModal}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* {cart && (
                            <CalculateVariantsQuantity
                              cartItems={cart
                                .filter(
                                  (cartObj, index) =>
                                  // cart.indexOf(cartObj) !== index
                                    cart.findIndex(
                                      (item) => Object.keys(item)[0] === Object.keys(cartObj)[0]
                                    ) === index
                                )
                                .reduce(
                                (newCartItem, oldCartItem) => {
                                  if (
                                    Array.isArray(Object.values(oldCartItem)[0])
                                  ) {
                                    newCartItem.push(
                                      Object.values(oldCartItem)[0][0]
                                    );
                                  }
                                  return newCartItem;
                                },
                                []
                              )}
                              // setIncrementVariantsModal={setIncrementVariantsModal}
                            />
                          )} */}
                          {/* </div> */}
                          {/* <span className="cart__order__price">
                              {`${
                                Array.isArray(Object.values(cartItem)[0])
                                  ? Object.values(
                                      cartItem
                                    )[0][0]?.product?.price.toFixed(2)
                                  : (
                                      cartItem.product?.price *
                                      itemsCount(cartItem)
                                    ).toFixed(2)
                              } ${storeCurrency}`}
                            </span> */}
                          {/* </div>
                        </div> */}
                        </div>
                      )}
                      {/* {index !==
                        cart.filter(
                          (cartObj, index) =>
                            cart.findIndex(
                              (item) =>
                                Object.keys(item)[0] === Object.keys(cartObj)[0]
                            ) === index
                        ).length -
                          1 && (
                        <hr className="m-0 border-solid border-b border-b-[rgba(0,0,0,1)] opacity-30" />
                      )} */}
                    </Fragment>
                  );
                })}

              {/* <span
                className="cart__clear"
                onClick={() => {
                  dispatch(clearCart());
                }}
              >
                <VscClearAll className="cart__clear__icon" />
                {storelanguage?.name === "English" ? (
                  <span>Clear Cart</span>
                ) : (
                  <span>Borrar todo</span>
                )}
              </span> */}
            </>
          ) : (
            <div className="cart__empty__message">
              {billdata?.state?.statetwo?.billDetailsTexts?.cartEmpty[storelanguage?.code]}
            </div>
          )}
        </div>
        {cart && cart.length > 0 && (
          <div className="flex items-center justify-end w-full">
            <span
              onClick={() => {
                dispatch(clearCart());
              }}
              className="text-[#E50000] font-bold text-[12px] my-4 cursor-pointer"
            >
              {billdata?.state?.statetwo?.billDetailsTexts?.clearCart[storelanguage?.code]}
            </span>
          </div>
        )}
        {/* <div className="BillDetails_page_BillTotal_wrapper">
          {storelanguage?.name === "English" ? (
            <h3> Bill Details</h3>
          ) : (
            <h3> Detalles de pago</h3>
          )}
          <div
            className="DetailsPage-content-wrapper"
            style={{ paddingTop: deliveryCharge > 0 && "10px" }}
          >
            {deliveryCharge > 0 && (
              <>
                <div className="d_grid_column BillDetails_item_total_wrapper">
                  <div className="text_align_start">
                    {" "}
                    {storelanguage?.name === "English" ? (
                      <h4> Item Total </h4>
                    ) : (
                      <h4> Suma total de los artículos </h4>
                    )}
                  </div>
                  <div className="text_align_end">
                    {" "}
                    <p
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      {currencyFormatter(
                        Math.abs(totalPrice).toFixed(2),
                        storeCurrency
                      )}
                    </p>
                  </div>
                </div>
                <div className="d_grid_column BillDetails_item_total_wrapper">
                  <div className="text_align_start">
                    {" "}
                    {storelanguage?.name === "English" ? (
                      <h4> Delivery Fee</h4>
                    ) : (
                      <h4> Gastos de envío </h4>
                    )}
                  </div>
                  <div className="text_align_end">
                    {" "}
                    <p
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {currencyFormatter(
                        Math.abs(deliveryCharge).toFixed(2),
                        storeCurrency
                      )}
                    </p>
                  </div>
                </div>
              </>
            )}
            
            <div className="d_grid_column BillDetails_item_topay_wrapper">
              <div className="text_align_start">
                {" "}
                {storelanguage?.name === "English" ? (
                  <p> To Pay</p>
                ) : (
                  <p> Total a pagar </p>
                )}{" "}
              </div>
              <div className="text_align_end">
                {" "}
                <p>
                  {" "}
                  {currencyFormatter(
                    Math.abs(totalPrice + deliveryCharge + totaltax).toFixed(2),
                    storeCurrency
                  )}
                </p>
              </div>
            </div>
          </div>
        </div> */}

        {/* {false && (
          <div className="discount__container">
            <span className="text-sm font-bold text-green-600">
              {storelanguage?.name === "English"
                ? storeDiscount.description?.en
                : storeDiscount.description?.it}
            </span>
            <div className="flex items-center justify-end w-full">
              <button
                // onClick={handleproceedbutton}
                className="BillPage_avail_discount_btn"
              >
                {" "}
                {storelanguage?.name === "English" ? "Avail" : "aprovechar"}
              </button>
            </div>
          </div>
        )} */}
        <div className="Billpage_proceed_tonext_btn_wrapper">
          <h3 className="!text-white text-[22px]">
            {" "}
            {currencyFormatter(
              Math.abs(totalPrice + totaltax).toFixed(2),
              storeCurrency
            )}
          </h3>
          <button
            onClick={handleproceedbutton}
            className="BillPage_proceed_to_btn"
          >
            {" "}
            {billdata?.state?.statetwo?.billDetailsTexts?.proceed[storelanguage?.code]}
            {/* {storelanguage?.name === "English" ? "Proceed to Pay" : "Continuar"} */}
          </button>
        </div>
      </div>
      <Modal
        open={open}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="Sorry_div_wrapper">
            <div className="Sorry_div_wrapper_inner_div" onClick={handleClose}>
              <HighlightOffIcon style={{ fontSize: "35px", margin: "5px" }} />
              <p style={{ fontSize: "17px" }}>
                {" "}
                {storelanguage?.name === "English" ? "Close" : "Cerca"}
              </p>
            </div>
            <img src="/assets/images/Emoji_logo.png" alt="" />
            <h4>
              {" "}
              {storelanguage?.name === "English"
                ? "Sorry !!!"
                : "Lo siento !!!"}{" "}
            </h4>
            <p className="Sorry_div_wrapper_p">
              {" "}
              {storelanguage?.name === "English"
                ? "Store is closed, please try again later"
                : "La tienda está cerrada, inténtalo de nuevo más tarde"}{" "}
            </p>
            {/* <button className="Sorry_div_wrapper_next_button" onClick={handleNextButton}> View Cart</button> */}
          </div>
        </Box>
      </Modal>

      <Footer />
    </div>
  );
}

export default BillDetails;
