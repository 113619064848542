import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Header from "./Header";
import { useTranslation } from "react-i18next";
import { useLocation, useSearchParams } from "react-router-dom";
import Footer from "./Footer";
import { emphasize } from "@mui/material";
import {
  ETAtime,
  ETAtimetwo,
  getdeliverytime,
  getOrderDetails,
  getOrderPin,
  getSiteDetails,
  OrderQrcode,
  sendmsglocation,
} from "../API";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import OTPInput, { ResendOTP } from "otp-input-react";
import { loadStripe } from "@stripe/stripe-js";
import HeaderCongratulations from "./HeaderCongratulations";
import { useSelector } from "react-redux";
import { currencyFormatter } from "../utils";
import { ImSpoonKnife } from "react-icons/im";

function Congratulations() {
  const [paymentIntentId, setPaymentIntentId] = useState(null);
  const [orderPin, setOrderPin] = useState(null);
  const [orderDetails, setOrderDetails] = useState(null);
  const { storeCurrency } = useSelector((state) => state.storeCurrencyReducer);
  const { storelanguage } = useSelector((state) => state.storelanguagereducer);
  const { confirmedCart, confirmedTotalItems, confirmedTotalPrice } =
    useSelector((state) => state.confirmedCartReducer);

  const locationName = localStorage.getItem("deliveryLocation");

  const selectedDelivery = sessionStorage.getItem("deliveryLocation");
  const [remaintime, setremaintime] = useState();
  const [open, setOpen] = React.useState(false);
  const [opentwo, setOpentwo] = React.useState(false);
  const [otp, setOtp] = useState("1234");
  const [etaTime, setEtaTime] = useState(null);
  var rString = "";
  var finalprice = 0;

  const orderData = useLocation();

  const [siteDetails, setSiteDetails] = useState();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 350,
    bgcolor: "#ffffff",
    boxShadow: 24,
    p: 4,
    borderRadius: "10px",
    paddingTop: "11px",
    paddingBottom: "0px",
    border: "none",
    paddingRight: "0px",
    paddingLeft: "0px",
    zIndex: "999999999999999999999999",
  };

  const handleClose = (reason) => {
    if (reason && reason == "backdropClick") return;
    setOpen(false);
  };

  const handleClosetwo = (reason) => {
    if (reason && reason == "backdropClick") return;
    setOpentwo(false);
  };

  const handleKeyDown = (event) => {
    event.preventDefault();
  };

  const itemsCount = (cartItem) => {
    if (Array.isArray(Object.values(cartItem)[0])) {
      // return cartItem[0].product.image
      return 1;
    } else {
      return Object.values(cartItem)[0];
    }
  };

  const CalculateVariantsQuantity = ({ cartItems }) => {
    let filteredCartItems = cartItems.reduce((uniqueVariant, variant) => {
      if (!uniqueVariant[Object.keys(variant)[0]]) {
        let uniqueVariantObject = Object.keys(variant).includes(
          "multipleVariants"
        )
          ? {
              variantCount: 1,
              product: Object.values(variant)[1],
              productVariant: Object.values(variant)[2],
              multipleVariants: Object.values(variant)[3],
            }
          : {
              variantCount: 1,
              product: Object.values(variant)[1],
              productVariant: Object.values(variant)[2],
            };
        uniqueVariant[Object.keys(variant)[0]] = uniqueVariantObject;
      } else if (uniqueVariant[Object.keys(variant)[0]]) {
        uniqueVariant[Object.keys(variant)[0]].variantCount += 1;
      }

      return uniqueVariant;
    }, []);

    return (
      <div className="flex flex-col gap-2">
        {Object.values(filteredCartItems).length > 0 &&
          Object.values(filteredCartItems).map((cartItem, index) => (
            <div
              key={"filtered__cart__item__no__" + index}
              // className="flex flex-col gap-1"
              className="grid grid-cols-[50%_50%] items-center"
            >
              <span className="text-sm font-normal capitalize">
                {cartItem?.multipleVariants?.length > 0 ? (
                  <>
                    {cartItem.multipleVariants
                      ?.map((variant) => variant.name[storelanguage?.code])
                      ?.join(", ")
                      ?.toLowerCase()}
                  </>
                ) : (
                  <>
                    {storelanguage?.name === "English"
                      ? `${cartItem.productVariant?.name[
                          storelanguage?.code
                        ].toLowerCase()}`
                      : `${cartItem.productVariant?.name[
                          storelanguage?.code
                        ].toLowerCase()}`}
                  </>
                )}
              </span>
              <div className="cart__product__list__container">
                <div className="cart__product__list__wrapper !border-none !shadow-none">
                  <input
                    type="number"
                    style={{
                      WebkitAppearance: "none",
                      margin: 0,
                      color: "var(--theme-primary)",
                      minWidth: "10px",
                      textAlign: "end",
                    }}
                    readOnly
                    value={cartItem.variantCount}
                    className="ItemsListPage-ItemsList-Counter-input"
                  />
                </div>
                {/* <span className="cart__order__price !font-normal">
                  {currencyFormatter(
                    (cartItem.product.price * cartItem.variantCount).toFixed(2),
                    storeCurrency
                  )}
                </span> */}
              </div>
            </div>
          ))}
      </div>
    );
  };

  const variantCount = (cartItem) => {
    if (Array.isArray(Object.values(cartItem)[0])) {
      let quantityCount = Object.values(cartItem)[0].map(
        (variantItem, index) => {
          return 1;
        }
      );
      return quantityCount;
    } else {
      return `${Object.values(cartItem)[0]}`;
    }
  };

  finalprice = orderData?.state?.state?.availableDiscount?.discountedAmount
    ? parseFloat(
        orderData?.state?.state?.availableDiscount?.totalAfterDiscount
      ) +
      parseFloat(orderData?.state?.state?.totaltax) +
      parseFloat(orderData?.state?.state?.deliveryCharge)
    : parseFloat(orderData?.state?.state?.totalprice) +
      parseFloat(orderData?.state?.state?.totaltax) +
      parseFloat(orderData?.state?.state?.deliveryCharge);

  useEffect(() => {
    const intervalId = setInterval(() => {
      getdeliverytime(orderData?.state?.state?.orderId)
        .then((res) => {
          setEtaTime(res.data.result);
          // setETADetails(res?.data?.result);
          // setETALoader(false);
        })
        .catch((err) => {
          // setETALoader(true);
          console.log("Error ", err);
        });
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    getSiteDetails(process.env.REACT_APP_SITE_NAME)
      .then((res) => {
        setSiteDetails(res.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    // generateBarCode(orderData.state.newstring)
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
    let hrs = today.getHours(); // => 9
    let mins = today.getMinutes(); // =>  30
    var ampm = hrs >= 12 ? "PM" : "AM";
    hrs = hrs % 12;
    hrs = hrs ? hrs : 12;
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (hrs < 10) {
      hrs = "0" + hrs;
    }
    if (mins < 10) {
      mins = "0" + mins;
    }
    const formattedToday =
      mm + "-" + dd + "-" + yyyy + ", " + hrs + ":" + mins + "\xa0" + ampm;
    document.getElementById("DATE").innerHTML = formattedToday;
    //  setInterval(()=>{
    //   getdeliverytime(orderData?.state?.newstring).then((res)=>{
    //     // console.log('Response timer',res)
    //     setremaintime(res.data?.data[0]?.deliveryTime)
    //    }).catch((err)=>{
    //        console.log(err)
    //    })
    //  }, 4000)
    setOtp(orderData?.state?.state?.pin);
  }, []);

  useEffect(() => {
    if (
      !orderData?.state ||
      orderData?.state === undefined ||
      orderData?.state === null ||
      orderData?.state === "null"
    ) {
      window.location.replace("/");
    }
  }, [orderData?.state]);

  const getOrderStatus = (orderDetails) => {
    switch (orderDetails?.statusCode) {
      case 2: {
        return (
          <>
            {orderDetails?.status === "order accepted" ? (
              <>
                {
                  orderData?.state?.state?.statetwo?.congratulationTexts
                    ?.orderFlow?.orderAccepted[storelanguage?.code]
                }
              </>
            ) : orderDetails?.status === "enroute to pickup" ? (
              <>
                {
                  orderData?.state?.state?.statetwo?.congratulationTexts
                    ?.orderFlow?.orderEnroutePickup[storelanguage?.code]
                }{" "}
                {orderDetails?.pickup?.locationName[storelanguage?.code]}
              </>
            ) : (
              <>
                {
                  orderData?.state?.state?.statetwo?.congratulationTexts
                    ?.orderFlow?.orderPlaced[storelanguage?.code]
                }
              </>
            )}
          </>
        );
      }

      case 3:
      case 4: {
        return (
          <>
            {
              orderData?.state?.state?.statetwo?.congratulationTexts?.orderFlow
                ?.orderWaitingPickup[storelanguage?.code]
            }{" "}
            {orderDetails?.pickup?.locationName[storelanguage?.code]}
          </>
        );
      }

      case 5: {
        return (
          <>
            {
              orderData?.state?.state?.statetwo?.congratulationTexts?.orderFlow
                ?.orderPickedUp[storelanguage?.code]
            }
          </>
        );
      }

      case 6:
        return (
          <>
            {
              orderData?.state?.state?.statetwo?.congratulationTexts?.orderFlow
                ?.orderEnrouteDropoff[storelanguage?.code]
            }{" "}
            {orderDetails?.drop?.locationName[storelanguage?.code]}
          </>
        );

      case 7:
      case 8:
        return (
          <>
            {
              orderData?.state?.state?.statetwo?.congratulationTexts?.orderFlow
                ?.orderDropoff[storelanguage?.code]
            }{" "}
            {orderDetails?.drop?.locationName[storelanguage?.code]}
          </>
        );

      case 9:
        return (
          <>
            {
              orderData?.state?.state?.statetwo?.congratulationTexts?.orderFlow
                ?.orderDelivered[storelanguage?.code]
            }
          </>
        );
      case 10:
        return (
          <>
            {
              orderData?.state?.state?.statetwo?.congratulationTexts?.orderFlow
                ?.orderCancelled[storelanguage?.code]
            }
          </>
        );

      default:
        break;
    }
  };

  // const getOrderStatus = (orderDetails) => {
  //   switch (orderDetails?.statusCode) {
  //     // case 0:
  //     //   return (
  //     //     <>
  //     //       {storelanguage?.name === "English" ? (
  //     //         <>Your order has been accepted by the restaurant.</>
  //     //       ) : (
  //     //         <>"Su pedido ha sido aceptado por el restaurante."</>
  //     //       )}
  //     //     </>
  //     //   );

  //     case 2:
  //       return (
  //         <>
  //           {orderDetails?.status !== "order accepted" ? (
  //             storelanguage === "English" ? (
  //               <>Your Order has been placed</>
  //             ) : (
  //               <>Hemos recibido tu pedido</>
  //             )
  //           ) : storelanguage === "English" ? (
  //             <>Your order has been accepted by the restaurant.</>
  //           ) : (
  //             <>"Su pedido ha sido aceptado por el restaurante."</>
  //           )}
  //         </>
  //       );

  //     case 5:
  //       return (
  //         <>
  //           {storelanguage?.name === "English" ? (
  //             <>Your order has been picked up by the ottobot.</>
  //           ) : (
  //             <>"Tu pedido ha sido recogido por el ottobot."</>
  //           )}
  //         </>
  //       );

  // case 6:
  //   return (
  //     <>
  //       {storelanguage?.name === "English" ? (
  //         <>
  //           Your order is en-route to{" "}
  //           {
  //             orderDetails?.orderDropLocation?.locationName[
  //               storelanguage?.code
  //             ]
  //           }
  //           .
  //         </>
  //       ) : (
  //         <>
  //           "Su pedido está en camino a{" "}
  //           {
  //             orderDetails?.orderDropLocation?.locationName[
  //               storelanguage?.code
  //             ]
  //           }
  //           "
  //         </>
  //       )}
  //     </>
  //   );

  // case 7:
  // case 8:
  //   return (
  //     <>
  //       {storelanguage?.name === "English" ? (
  //         <>
  //           Your order has arrived at{" "}
  //           {
  //             orderDetails?.orderDropLocation?.locationName[
  //               storelanguage?.code
  //             ]
  //           }
  //           .
  //         </>
  //       ) : (
  //         <>
  //           Tu pedido ha llegado a{" "}
  //           {
  //             orderDetails?.orderDropLocation?.locationName[
  //               storelanguage?.code
  //             ]
  //           }
  //         </>
  //       )}
  //     </>
  //   );

  // case 9:
  //   return (
  //     <>
  //       {storelanguage?.name === "English" ? (
  //         <>Your order has been delivered.</>
  //       ) : (
  //         <>Tu pedido ha sido entregado.</>
  //       )}
  //     </>
  //   );
  // case 10:
  //   return (
  //     <>
  //       {storelanguage?.name === "English" ? (
  //         <>Your order has been cancelled.</>
  //       ) : (
  //         <>Tu pedido ha sido cancelado.</>
  //       )}
  //     </>
  //   );

  // default:
  //   break;
  //   }
  // };

  const customFormatTime = (seconds) => {
    let convertedTime = convertSeconds(seconds);

    let convertedTimeString = "";

    if (convertedTime.years && convertedTime.years !== 0) {
      convertedTimeString += `${convertedTime.years} y `;
    }

    if (convertedTime.months && convertedTime.months !== 0) {
      convertedTimeString += `${convertedTime.months} m `;
    }

    if (convertedTime.days && convertedTime.days !== 0) {
      convertedTimeString += `${convertedTime.days} d `;
    }

    if (convertedTime.hours && convertedTime.hours !== 0) {
      convertedTimeString += `${convertedTime.hours} hr `;
    }

    if (convertedTime.minutes && convertedTime.minutes !== 0) {
      convertedTimeString += `${convertedTime.minutes} ${
        convertedTime.minutes === 1 ? "min" : "mins"
      }`;
    }

    // if (convertedTime.seconds && convertedTime.seconds !== 0) {
    //   convertedTimeString += `${Math.floor(convertedTime.seconds)} sec `;
    // }

    return convertedTimeString;
  };

  const convertSeconds = (seconds) => {
    if (seconds <= 0) {
      return {
        years: 0,
        months: 0,
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    const secondsInMinute = 60;
    const secondsInHour = secondsInMinute * 60;
    const secondsInDay = secondsInHour * 24;
    const secondsInMonth = secondsInDay * 30;
    const secondsInYear = secondsInMonth * 12;

    if (seconds >= secondsInYear) {
      const years = Math.floor(seconds / secondsInYear);
      return {
        ...convertSeconds(seconds % secondsInYear),
        years,
      };
    } else if (seconds >= secondsInMonth) {
      const months = Math.floor(seconds / secondsInMonth);
      return {
        ...convertSeconds(seconds % secondsInMonth),
        months,
      };
    } else if (seconds >= secondsInDay) {
      const days = Math.floor(seconds / secondsInDay);
      return {
        ...convertSeconds(seconds % secondsInDay),
        days,
      };
    } else if (seconds >= secondsInHour) {
      const hours = Math.floor(seconds / secondsInHour);
      return {
        ...convertSeconds(seconds % secondsInHour),
        hours,
      };
    } else if (seconds >= secondsInMinute) {
      const minutes = Math.floor(seconds / secondsInMinute);
      return {
        ...convertSeconds(seconds % secondsInMinute),
        minutes,
      };
    } else {
      return {
        seconds,
      };
    }
  };

  const updatePin = useCallback(async () => {
    // getOrderPin(
    //   // orderData?.state?.state?.statetwo?.storeId,
    //   orderData?.state?.state?.orderId
    // )
    getOrderDetails(orderData?.state?.state?.orderId)
      .then((res) => {
        if (res.data.status === 200) {
          // setOrderPin(res.data.data.pin);
          // setOrderDetails(res.data.data);
          setOrderPin(res.data.data[0].dropPin);
          setOrderDetails(res.data.data[0]);
        } else {
          console.error(res.data.message);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [orderDetails]);

  const useIntervalAsync = (callbackFunction, intervalInMs) => {
    const timeout = useRef();

    const apiCall = useCallback(async () => {
      await callbackFunction();
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
      timeout.current = window.setTimeout(apiCall, intervalInMs);
    }, [callbackFunction, intervalInMs]);

    useEffect(() => {
      apiCall();

      return () => {
        clearTimeout(timeout.current);
      };
    }, []);
  };

  useIntervalAsync(updatePin, 3000);

  return (
    <>
      <div className="MainWhole-wrapper-container">
        <Header
          profile={true}
          orderData={orderData}
          congratulations={true}
          siteDetails={siteDetails}
        />
        <div className="Congratulation-Main-wrapper">
          <div
            className={
              ![10, 14, 15].includes(orderDetails?.statusCode)
                ? "rounded-b-xl Congratulation-content-div-one-wrapper"
                : "rounded-b-xl Congratulation-content-div-one-wrapper-cancelled"
            }
          >
            <div className="Congratulation-content-div-one-wrapper-inner-one">
              {![10, 14, 15].includes(orderDetails?.statusCode) ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="93.3"
                  height="93.3"
                  viewBox="0 0 93.3 93.3"
                >
                  <g
                    id="Group_33"
                    data-name="Group 33"
                    transform="translate(-43 -122)"
                  >
                    <g
                      id="noun-check-mark-747257"
                      transform="translate(-155.8 -6.8)"
                    >
                      <path
                        id="Path_28"
                        data-name="Path 28"
                        d="M245.45,128.8a46.65,46.65,0,1,0,46.65,46.65A46.677,46.677,0,0,0,245.45,128.8Zm0,3.455a43.194,43.194,0,1,1-43.194,43.195A43.168,43.168,0,0,1,245.45,132.255Zm25.7,20.733h0a1.723,1.723,0,0,0-1.026.539l-40.279,40.279-16.089-14.47h0a1.728,1.728,0,1,0-2.267,2.591l17.278,15.549h0a1.728,1.728,0,0,0,2.375-.054l6.222-6.222,35.245-35.245a1.728,1.728,0,0,0-1.457-2.97Z"
                        transform="translate(0)"
                        fill="white"
                      />
                    </g>
                  </g>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="78.746"
                  height="78.744"
                  viewBox="0 0 78.746 78.744"
                >
                  <g id="cross-1" transform="translate(-10.617 -10.64)">
                    <path
                      id="Path_336"
                      data-name="Path 336"
                      d="M50,10.64A39.37,39.37,0,1,0,77.83,22.17,39.372,39.372,0,0,0,50,10.64ZM75,75a35.34,35.34,0,1,1,0-50,35.34,35.34,0,0,1,0,50Z"
                      fill="#fff"
                    />
                    <path
                      id="Path_337"
                      data-name="Path 337"
                      d="M62.8,34.37,50,47.17,37.2,34.37,34.37,37.2,47.17,50,34.37,62.8l2.83,2.83L50,52.83l12.8,12.8,2.83-2.83L52.83,50l12.8-12.8Z"
                      fill="#fff"
                    />
                  </g>
                </svg>
              )}
            </div>
            <div className="Congratulation-content-div-one-wrapper-inner-two">
              <h1 className="!text-[22px]">
                {/* {console.log(orderData, "orderData")} */}
                {![10, 14, 15].includes(orderDetails?.statusCode)
                  ? orderData?.state?.state?.statetwo?.congratulationTexts
                      ?.title[storelanguage?.code]
                  : orderDetails?.cancellationReason?.text}
                {/* {storelanguage?.name === "English"
                  ? "Congratulations"
                  : "Gracias"} */}
              </h1>
              <p className="!text-white !text-[14px]">
                {" "}
                {getOrderStatus(orderDetails)}
              </p>
            </div>
          </div>
          <div className="Congratulation-page-order-wrapper-below-QR">
            <div className="!text-[14px] !font-bold">
              <h5 className="!text-[12px]">
                {" "}
                {
                  orderData?.state?.state?.statetwo?.congratulationTexts
                    ?.orderId[storelanguage?.code]
                }
                {/* {storelanguage?.name === "English"
                  ? "Order ID"
                  : "Número de pedido:"} */}{" "}
              </h5>
              {orderData?.state?.state?.orderId}
            </div>
            <div>
              <h5 className="!text-[12px]">
                {
                  orderData?.state?.state?.statetwo?.congratulationTexts
                    ?.orderDate[storelanguage?.code]
                }
                {/* {storelanguage?.name === "English" ? "Order Date" : "Fecha"} */}
              </h5>
              <p className="!text-[14px] !font-bold" id="DATE">
                {" "}
              </p>
            </div>
            <div>
              <h5 className="!text-[12px]">
                {" "}
                {
                  orderData?.state?.state?.statetwo?.congratulationTexts
                    ?.payment[storelanguage?.code]
                }
                {/* {storelanguage?.name === "English"
                  ? "Payment"
                  : "Método de pago"}{" "} */}
              </h5>
              <p className="!text-[14px] !font-bold">
                {" "}
                {storelanguage?.name === "English" ? "Stripe" : "Stripe"}
              </p>
            </div>
          </div>
          {orderPin &&
            orderDetails?.statusCode > 5 &&
            orderDetails?.statusCode < 9 && (
              <div className="Congratulations_page_QRcode_pin_wrapper">
                {/* <h3>
                {storelanguage?.name === "English" ? (
                  <>
                    {orderDetails?.orderStatus?.toLowerCase() === "order booked"
                      ? "Your order has been accepted by the restaurant."
                      : orderDetails?.orderStatus}
                  </>
                ) : (
                  <>
                    {orderDetails?.orderStatus?.toLowerCase() === "order booked"
                      ? "Su pedido ha sido aceptado por el restaurante"
                      : orderDetails?.orderStatus}
                  </>
                )}
              </h3> */}
                <h3>
                  {" "}
                  {
                    orderData?.state?.state?.statetwo?.congratulationTexts?.pin[
                      storelanguage?.code
                    ]
                  }
                  {/* {storelanguage?.name === "English"
                    ? "PIN to access your package"
                    : "PIN para acceder a tu pedido"} */}{" "}
                </h3>
                <div className="QR-Code-main-inner">
                  <OTPInput
                    inputClassName="readonly-input"
                    onKeyDown={handleKeyDown}
                    value={orderPin}
                    onChange={setOrderPin}
                    OTPLength={orderPin?.length ?? 4}
                    otpType="number"
                    disabled={false}
                    className="Congratulation_page_otp_input"
                  />
                </div>
              </div>
            )}
          {["order booked", "order confirmed"].includes(
            orderDetails?.status
          ) && (
            <div className="relative flex items-end justify-center h-[115px] m-6 bg-white rounded-xl p-[1rem] !mt-16">
              <span className="absolute -top-10 flex items-center justify-center w-[70px] h-[70px] mx-auto bg-white border !border-[var(--theme-primary)] rounded-full !p-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="29.669"
                  height="43.025"
                  viewBox="0 0 29.669 43.025"
                  className="h-full w-full text-[var(--theme-primary)]"
                >
                  <g
                    id="noun-restaurant-5208545"
                    transform="translate(-56.36 -6.72)"
                  >
                    <g
                      id="Group_92"
                      data-name="Group 92"
                      transform="translate(56.36 6.72)"
                    >
                      <path
                        id="Path_202"
                        data-name="Path 202"
                        d="M67.606,7.94a.851.851,0,1,1,1.7,0v7.949a6.412,6.412,0,0,1-.956,3.375A6.533,6.533,0,0,1,66.226,21.4V46.45a3.118,3.118,0,0,1-2.047,3.044,3.722,3.722,0,0,1-2.69,0,3.118,3.118,0,0,1-2.047-3.044V21.393a6.525,6.525,0,0,1-2.126-2.129,6.427,6.427,0,0,1-.955-3.375V7.94a.851.851,0,0,1,1.7,0v7.949a4.722,4.722,0,0,0,.7,2.483,4.829,4.829,0,0,0,1.848,1.738.853.853,0,0,1,.533.79v25.55a1.48,1.48,0,0,0,.955,1.459,2.1,2.1,0,0,0,1.466,0,1.482,1.482,0,0,0,.955-1.459V20.9a.855.855,0,0,1,.465-.759A4.834,4.834,0,0,0,66.9,18.372a4.726,4.726,0,0,0,.7-2.483V7.94Zm-3.918,7.949a.851.851,0,1,1-1.7,0V7.94a.851.851,0,1,1,1.7,0ZM78.54,11.773a.851.851,0,0,1,0-1.7,3.582,3.582,0,0,1,2.876,1.587,5.935,5.935,0,0,1,1.073,3.488.851.851,0,1,1-1.7,0,4.227,4.227,0,0,0-.741-2.483,1.929,1.929,0,0,0-1.505-.888Zm3.833,12.4V46.45a3.118,3.118,0,0,1-2.047,3.044,3.722,3.722,0,0,1-2.69,0,3.118,3.118,0,0,1-2.047-3.044V24.174a8.337,8.337,0,0,1-2.582-3.215,11.563,11.563,0,0,1-1.073-4.943,11.011,11.011,0,0,1,1.981-6.473,5.958,5.958,0,0,1,10.133,0,11.014,11.014,0,0,1,1.981,6.473,11.572,11.572,0,0,1-1.073,4.943A8.361,8.361,0,0,1,82.374,24.174Zm-1.7,22.276V23.722h0A.848.848,0,0,1,81.08,23a6.41,6.41,0,0,0,2.34-2.763,9.88,9.88,0,0,0,.907-4.217,9.3,9.3,0,0,0-1.649-5.469,4.278,4.278,0,0,0-7.392,0,9.3,9.3,0,0,0-1.649,5.469,9.876,9.876,0,0,0,.907,4.217,6.445,6.445,0,0,0,2.295,2.733.853.853,0,0,1,.456.755V46.45a1.481,1.481,0,0,0,.956,1.459,2.1,2.1,0,0,0,1.465,0,1.481,1.481,0,0,0,.956-1.459ZM64.8,7.94a.851.851,0,0,1,1.7,0v7.949a.851.851,0,0,1-1.7,0Zm-3.922,7.949a.851.851,0,1,1-1.7,0V7.94a.851.851,0,0,1,1.7,0Z"
                        transform="translate(-56.36 -6.72)"
                        fill="var(--theme-primary)"
                      />
                    </g>
                  </g>
                </svg>
                {/* <ImSpoonKnife className="h-full w-full text-[var(--theme-primary)]" /> */}
              </span>
              <span className="text-[18px] font-bold">
                {" "}
                {
                  orderData?.state?.state?.statetwo?.congratulationTexts
                    ?.waiting[storelanguage?.code]
                }
                {/* {storelanguage?.name === "English"
                  ? "Waiting for the restaurant to accept your order..."
                  : "Esperando a que el restaurante acepte tu pedido..."} */}{" "}
              </span>
            </div>
          )}
          <Modal
            open={open}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div className="QR-Code-wrapper-whole">
                <div className="QR-Code-wrapper-whole-inner-one">
                  <h3>
                    {" "}
                    {storelanguage?.name === "English"
                      ? "Scan QR Code"
                      : "Escanear código QR"}
                  </h3>
                  <div
                    className="Sorry_div_wrapper_inner_div"
                    style={{ paddingBottom: "0px" }}
                    onClick={handleClose}
                  >
                    <HighlightOffIcon
                      style={{ fontSize: "35px", margin: "5px" }}
                    />
                    <p style={{ fontSize: "17px" }}>
                      {storelanguage?.name === "English" ? "Close" : "Cerca"}{" "}
                    </p>
                  </div>
                </div>
                <p style={{ marginBottom: "12px" }}>
                  {" "}
                  {storelanguage?.name === "English"
                    ? "Show the QR code to the camera"
                    : "Mostrar el código QR a la cámara"}{" "}
                </p>
                <div className="QR-Code-main-inner">
                  <img
                    id="barcode"
                    src="https://api.qrserver.com/v1/create-qr-code/?data=Congratulation&amp;size=100x100"
                    alt=""
                    title="Congratualtions"
                    width="500"
                    height="500"
                    style={{ objectFit: "cover" }}
                  />
                </div>
              </div>
            </Box>
          </Modal>
          <Modal
            open={opentwo}
            // BackdropProps={false}
            onHide={handleClosetwo}
            // onClose={handleClose}
            backdrop="static"
            keyboard={false}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div className="QR-Code-wrapper-whole">
                <div className="QR-Code-wrapper-whole-inner-one">
                  <h3>
                    {" "}
                    {storelanguage?.name === "English"
                      ? "Your PIN"
                      : "Su PIN"}{" "}
                  </h3>
                  <div
                    className="Sorry_div_wrapper_inner_div"
                    style={{ paddingBottom: "0px" }}
                    onClick={handleClosetwo}
                  >
                    <HighlightOffIcon
                      style={{ fontSize: "35px", margin: "5px" }}
                    />
                    <p style={{ fontSize: "17px" }}>
                      {" "}
                      {storelanguage?.name === "English" ? "Close" : "Cerca"}
                    </p>
                  </div>
                </div>
                <p style={{ marginBottom: "12px" }}>
                  {" "}
                  {storelanguage?.name === "English"
                    ? "Enter the PIN on the Top Screen"
                    : "Ingrese el PIN en la pantalla superior"}{" "}
                </p>
                <div className="QR-Code-main-inner">
                  <OTPInput
                    value={orderPin}
                    onChange={setOrderPin}
                    autoFocus
                    OTPLength={orderPin?.length ?? 4}
                    otpType="number"
                    disabled={true}
                    className="Cart_page_otp_input"
                  />
                </div>
              </div>
            </Box>
          </Modal>
          <div
            className="BillDetails_page_BillTotal_wrapper"
            style={{ margin: "20px" }}
          >
            <h3 className="!text-black !text-[18px]">
              {" "}
              {
                orderData?.state?.state?.statetwo?.congratulationTexts
                  ?.orderDetails[storelanguage?.code]
              }
              {/* {storelanguage?.name === "English"
                ? "Order Details"
                : "Detalles del pedido"} */}{" "}
            </h3>
            {/* <div className="DetailsPage-content-wrapper !px-[20px]">
              {cart &&
                cart.map((cartItem, index) => {
                  return (
                    <div
                      key={"cart__item__no__" + index}
                      className="ItemsListPage-ItemsList-containers"
                    >
                      <div className="product-content-wrapper">
                        <div className="product-content-wrapper-inner-div-one">
                          <img
                            src={
                              Array.isArray(Object.values(cartItem)[0])
                                ? Object.values(cartItem)[0][0].product.image
                                : cartItem.product?.image
                            }
                            className="product-images-class"
                            alt=""
                          />
                        </div>
                        <div className="product-content-wrapper-inner-div-two">
                          <h4>
                            {" "}
                            {storelanguage?.name === "English" ? (
                              <>
                                {Array.isArray(Object.values(cartItem)[0])
                                  ? Object.values(cartItem)[0][0].product?.en
                                      ?.name
                                  : cartItem.product?.en?.name}
                              </>
                            ) : (
                              <>
                                {Array.isArray(Object.values(cartItem)[0])
                                  ? Object.values(cartItem)[0][0].product?.it
                                      ?.name
                                  : cartItem.product?.it?.name}
                              </>
                            )}{" "}
                          </h4>
                          {variantCount(cartItem)}
                        </div>
                      </div>
                      <p style={{ textAlign: "end", fontWeight: "bold" }}>
                        {Array.isArray(Object.values(cartItem)[0])
                          ? currencyFormatter(
                              Object.values(
                                cartItem
                              )[0][0]?.product?.price.toFixed(2),
                              storeCurrency
                            )
                          : currencyFormatter(
                              (
                                cartItem.product?.price * itemsCount(cartItem)
                              ).toFixed(2),
                              storeCurrency
                            )}
                      </p>
                    </div>
                  );
                })}
              {orderData?.state?.state?.checkoutData?.map((item, index) => {
                return (
                  <div
                    key={"cart__order__no__" + index}
                    className="d_grid_column BillDetails_item_total_wrapper"
                  >
                    <div className="text_align_start">
                      {" "}
                      <h4>
                        {" "}
                        {storelanguage?.name === "English" ? (
                          <>{item.en.name} </>
                        ) : (
                          <>{item.it.name} </>
                        )}{" "}
                        x{item.count}{" "}
                      </h4>
                    </div>
                    <div className="text_align_end">
                      {" "}
                      <p>
                        {currencyFormatter(
                          item.price.toFixed(2),
                          storeCurrency
                        )}
                      </p>
                    </div>
                  </div>
                );
              })}
              <div className="BillDetails_item_totalwhole_wrapper">
                {orderData?.state?.state?.deliveryCharge > 0 && (
                  <div className="d_grid_column BillDetails_item_total_wrapper">
                    <div className="text_align_start">
                      {" "}
                      <h4>
                        {" "}
                        {storelanguage?.name === "English"
                          ? "Delivery Fee"
                          : "Gastos de envío"}{" "}
                      </h4>
                    </div>
                    <div className="text_align_end">
                      {" "}
                      <p>
                        {" "}
                        {currencyFormatter(
                          orderData?.state?.state?.deliveryCharge.toFixed(2),
                          storeCurrency
                        )}
                      </p>
                    </div>
                  </div>
                )}
                <div className="d_grid_column BillDetails_item_totalprice_wrapper">
                  <div className="text_align_start">
                    {" "}
                    <p> {storelanguage?.name === "English" ? "Total:" : "Total:"} </p>
                  </div>
                  <div className="text_align_end">
                    {" "}
                    <p>
                      {currencyFormatter(finalprice.toFixed(2), storeCurrency)}
                    </p>
                  </div>
                </div>
              </div>
              <div className="d_grid_column BillDetails_item_topay_wrapper">
                <div className="text_align_start">
                  {" "}
                  <p>
                    {" "}
                    {storelanguage?.name === "English"
                      ? "Selected Delivery Location:"
                      : "Punto de entrega seleccionado:"}
                  </p>
                </div>
                <div className="text_align_end">
                  {" "}
                  <p>
                    {" "}
                    {orderData?.state?.state?.deliveryGate
                      ?.split("_")
                      .join(" ")}
                  </p>
                </div>
              </div>
            </div> */}
            <div className="mb-4 DetailsPage-content-wrapper">
              {confirmedCart && confirmedCart.length > 0 && (
                <>
                  {confirmedCart
                    .filter(
                      (cartObj, index) =>
                        confirmedCart.findIndex(
                          (item) =>
                            Object.keys(item)[0] === Object.keys(cartObj)[0]
                        ) === index
                    )
                    .map((cartItem, index) => {
                      return (
                        <Fragment key={"cart__item__no__" + index}>
                          {!Array.isArray(Object.values(cartItem)[0]) ? (
                            <div
                              key={"cartItem" + index}
                              className={`mb-2 cart__item__list__container ${
                                confirmedCart.length === 1 &&
                                "border-b-[0.25rem] border-b-[var(--theme-primary)] !mb-0 pb-[12px]"
                              }`}
                            >
                              <>
                                <div className="h-full product-content-wrapper">
                                  <div className="product-content-wrapper-inner-div-two">
                                    <h4 className="!text-[14px] !leading-5 !font-normal">
                                      {" "}
                                      {
                                        <>
                                          {Array.isArray(
                                            Object.values(cartItem)[0]
                                          )
                                            ? `${
                                                Object.values(cartItem)[0][0]
                                                  .product?.name[
                                                  storelanguage?.code
                                                ]
                                              } - ${
                                                Object.values(cartItem)[0][0]
                                                  .productVariant?.name[
                                                  storelanguage?.code
                                                ]
                                              }`
                                            : cartItem.product?.name[
                                                storelanguage?.code
                                              ]}
                                        </>
                                      }{" "}
                                    </h4>
                                  </div>
                                </div>
                                <div className="cart__product__list__container">
                                  <div className="cart__product__list__wrapper !border-none !shadow-none">
                                    {confirmedCart && (
                                      <>
                                        <input
                                          type="number"
                                          style={{
                                            WebkitAppearance: "none",
                                            margin: 0,
                                            color: "var(--theme-primary)",
                                            minWidth: "20px",
                                            textAlign: "end",
                                          }}
                                          readOnly
                                          value={variantCount(cartItem)}
                                          className="ItemsListPage-ItemsList-Counter-input"
                                        />
                                      </>
                                    )}
                                  </div>
                                  {/* <span className="cart__order__price !font-normal">
                                    {Array.isArray(Object.values(cartItem)[0])
                                      ? currencyFormatter(
                                          Object.values(
                                            cartItem
                                          )[0][0]?.product?.price.toFixed(2),
                                          storeCurrency
                                        )
                                      : currencyFormatter(
                                          (
                                            cartItem.product?.price *
                                            itemsCount(cartItem)
                                          ).toFixed(2),
                                          storeCurrency
                                        )}
                                  </span> */}
                                </div>
                              </>
                            </div>
                          ) : (
                            <div
                              key={"cartItem" + index}
                              className={`px-[10px] ${
                                index !==
                                confirmedCart.filter(
                                  (cartObj, index) =>
                                    confirmedCart.findIndex(
                                      (item) =>
                                        Object.keys(item)[0] ===
                                        Object.keys(cartObj)[0]
                                    ) === index
                                ).length -
                                  1
                                  ? "border-b-[#ddd] border-b-[2px] border-solid pb-[12px] mb-2"
                                  : "border-b-[var(--theme-primary)] border-b-[0.25rem] border-solid pb-[12px] mb-2"
                              } items-center flex ${
                                index !== 0 ? "pt-[1rem]" : ""
                              }`}
                            >
                              <div className="h-full product-content-wrapper">
                                <div className="gap-2 product-content-wrapper-inner-div-two">
                                  <h4 className="!text-[14px] !leading-5 !font-normal">
                                    {
                                      Object.values(cartItem)[0][0].product
                                        ?.name[storelanguage?.code]
                                    }
                                  </h4>
                                  <div className="mb-0 variant__increment__modal__variant__details__wrapper">
                                    <div className="variant__increment__modal__variant__wrapper">
                                      {confirmedCart && (
                                        <CalculateVariantsQuantity
                                          key={
                                            "calculate__variant__quantity__" +
                                            index
                                          }
                                          cartItems={confirmedCart.reduce(
                                            (newCartItem, oldCartItem) => {
                                              if (
                                                Array.isArray(
                                                  Object.values(oldCartItem)[0]
                                                ) &&
                                                Object.keys(cartItem)[0] ===
                                                  Object.keys(oldCartItem)[0]
                                              ) {
                                                newCartItem.push(
                                                  Object.values(
                                                    oldCartItem
                                                  )[0][0]
                                                );
                                              }
                                              return newCartItem;
                                            },
                                            []
                                          )}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Fragment>
                      );
                    })}
                </>
              )}

              <div className="BillDetails_item_topay_wrapper">
                <div className="self-center text_align_start">
                  {" "}
                  <p className="!text-[14px] !text-black !font-normal">
                    {" "}
                    {
                      orderData?.state?.state?.statetwo?.congratulationTexts
                        ?.selectedLocation[storelanguage?.code]
                    }
                    {/* {storelanguage?.name === "English"
                      ? "Selected Location:"
                      : "Punto de recogida seleccionado:"} */}{" "}
                  </p>
                </div>
                <div className="flex flex-col text_align_end">
                  {" "}
                  <span className="!font-normal !text-[14px] !text-black">
                    {storelanguage?.name === "English"
                      ? orderData?.state?.state?.cartdata?.state?.locationName?.group
                          ?.split("_")
                          ?.join(" ")
                      : orderData?.state?.state?.cartdata?.state?.locationName?.group
                          ?.split("_")
                          ?.join(" ")}
                  </span>
                  <span className="!font-bold !text-[14px] !text-[var(--theme-primary)] !capitalize">
                    {orderData?.state?.state?.cartdata?.state?.locationName?.locationName[
                      storelanguage?.code
                    ]
                      ?.split("_")
                      ?.join(" ")
                      ?.toLowerCase()}
                  </span>
                </div>
              </div>
            </div>
          </div>
          {[2, 3, 4, 5, 6, 7, 8].includes(orderDetails?.statusCode) &&
            !["order booked", "order confirmed"].includes(
              orderDetails?.status
            ) && (
              <>
                {[7, 8].includes(orderDetails?.statusCode) ? (
                  <div
                    className="!flex items-center justify-center Congratulations_page_estimated_time_div !bg-white"
                    id="Estimated_time"
                  >
                    
                    <span className="bg-transparent BillPage_proceed_to_btn">
                      Ottobot has reached
                    </span>
                  </div>
                ) : (
                  <div
                    className="Congratulations_page_estimated_time_div"
                    id="Estimated_time"
                  >
                    <p className="pl-[14px] text-[18px] text-white leading-5">
                      {" "}
                      {
                        orderData?.state?.state?.statetwo?.congratulationTexts
                          ?.ETA[storelanguage?.code]
                      }{" "}
                    </p>
                    <span className="BillPage_proceed_to_btn">
                      {etaTime !== null
                        ? Math.ceil(etaTime / 60) > 1
                          ? Math.ceil(etaTime / 60) + " mins"
                          : "1 min"
                        : "15 - 30 Mins"}{" "}
                    </span>
                  </div>
                )}
              </>
            )}
        </div>
      </div>

      <Footer open={open} opentwo={opentwo} />
    </>
  );
}

export default Congratulations;
