import React, { Suspense } from 'react';
import {ToastContainer} from "react-toastify"
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import {store } from './redux/store';
import { Provider } from 'react-redux';
import i18n from "i18next";
import {  initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
// import { persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';

// i18n
//   .use(initReactI18next) 
//   .use(HttpApi)
//   .use(LanguageDetector)
//   // passes i18n down to react-i18next
//   .init({
//     supportedLngs:['it','en'], 
//     fallbackLng: "en",
//     detection:{
//         order: ['path','cookie','htmlTag', 'localStorage' , 'subdomain'],
//         caches:['cookie']
//       },
//       backend:{
//         loadPath: 'assets/locales/{{lng}}/translation.json'
//       },
//       react:{useSuspense:false}
//     });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 <> 
    {/* <React.StrictMode> */}
    <BrowserRouter>
    <Provider store={store}>
      {/* <PersistGate loading={null} > */}
        <App />
        <ToastContainer />
      {/* </PersistGate> */}
    </Provider>
    </BrowserRouter>
  {/* </React.StrictMode> */}
</>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
