import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { GiCheckMark } from "react-icons/gi";
import { FiCheck } from "react-icons/fi";
import { DecrementCount, IncrementCount } from "../redux/Actions";
import { currencyFormatter } from "../utils";

const RecommendedProduct = ({
  recommendedProduct,
  storeCurrency,
  index,
  selectedProductDetails,
  cart,
  totalTempPrice,
  setTotalTempPrice,
  incrementTempVariantCount,
  decrementTempVariantCount,
  calculateQuantity,
  totalPrice,
  storedata
}) => {
  const [checkedProduct, setCheckedProduct] = useState(false);
  const { storelanguage } = useSelector((state) => state.storelanguagereducer);
  const {
    cart: mainCart,
    totalitems,
    totalPrice: mainTotalPrice,
  } = useSelector((state) => state.IC);

  const dispatch = useDispatch();

  return (
    <>
      <div className="variant__modal__recommended__product__div">
        <div className="variant__modal__recommended__product__div__wrapper">
          <img
            className="variants__modal__recommended__product__image rounded-[10px] shadow-[0px_0px_6px_#00000038]"
            src={recommendedProduct?.product?.image}
            alt=""
          />
          <div className="variants__modal__recommended__product___details">
            <span className="variants__modal__product___name">
              {recommendedProduct?.product?.name&&recommendedProduct?.product?.name[storelanguage?.code]}
            </span>
            <span className="variants__modal__product___price">
              {currencyFormatter(
                recommendedProduct?.product?.price?.toFixed(2),
                storeCurrency
              )}
            </span>
            <div className="variants__modal__recommended__product__add__btn__wrapper px-[0.615rem] py-1 mt-2">
              {mainCart &&
              (mainCart.findIndex(
                (cartItem) =>
                  Object.keys(cartItem)[0] === recommendedProduct?.product?._id
              ) === -1 ||
                mainCart[
                  mainCart.findIndex(
                    (cartItem) =>
                      Object.keys(cartItem)[0] ===
                      recommendedProduct?.product?._id
                  )
                ][recommendedProduct?.product?._id] === 0) ? (
                <button
                  className="variants__modal__recommended__product__add__btn rounded-[10px]"
                  onClick={() => {
                    dispatch(
                      IncrementCount(
                        recommendedProduct?.product,
                        mainCart,
                        mainTotalPrice
                      )
                    );
                  }}
                >
                  {" "}
                  {storedata?.state?.item?.productListTexts?.addButtonText[storelanguage?.code]}
                </button>
              ) : (
                <>
                  <button
                    className="bg-white border-none px-[0.615rem] py-0 rounded-[10px]"
                    onClick={() => {
                      dispatch(
                        DecrementCount(
                          recommendedProduct?.product,
                          mainCart,
                          mainTotalPrice
                        )
                      );
                    }}
                  >
                    {" "}
                    -{" "}
                  </button>
                  <input
                    type="number"
                    style={{
                      WebkitAppearance: "none",
                      margin: 0,
                      color: "var(--theme-primary)",
                      minWidth: "10px",
                      fontSize: "14px",
                    }}
                    readOnly
                    value={calculateQuantity(
                      recommendedProduct?.product,
                      mainCart
                    )}
                    className="ItemsListPage-ItemsList-Counter-input"
                  />
                  <button
                    className="bg-white border-none px-[0.615rem] py-0 rounded-[10px]"
                    onClick={() => {
                      dispatch(
                        IncrementCount(
                          recommendedProduct?.product,
                          mainCart,
                          mainTotalPrice
                        )
                      );
                    }}
                  >
                    {" "}
                    +{" "}
                  </button>{" "}
                </>
              )}
              {/* {checkedProduct ? (
            <button
              className="variants__modal__recommended__product__checked__btn"
              onClick={() => {
                setCheckedProduct(false);

                decrementTempVariantCount(
                  recommendedProduct?.product,
                  cart,
                  totalPrice,
                  undefined
                );
              }}
            >
              <FiCheck className="variants__modal__recommended__product__checked__btn__icon" />
            </button>
          ) : (
            <button
              className="variants__modal__recommended__product__add__btn"
              onClick={() => {
                setCheckedProduct(true);

                incrementTempVariantCount(
                  recommendedProduct?.product,
                  cart,
                  totalPrice,
                  undefined
                );
              }}
            >
              {storelanguage?.name === "English" ? "ADD" : "Agregar"}
            </button>
          )} */}
            </div>
          </div>
        </div>
      </div>
      {index !== selectedProductDetails?.recommendedProducts.length - 1 && (
        <hr className="recommended__product__divider border-b-[1px] !mx-6 !opacity-50" />
      )}
    </>
  );
};

export default RecommendedProduct;
